import React from 'react';

import { IF, Space } from '~/shared/components';

export const AdvantageParagraph = ({ className, title, description, needDot = false }) => (
  <p className={className}>
    <b>
      {title}
      <IF condition={needDot}>.</IF>
    </b>
    <Space />
    {description}
  </p>
);
