// extracted by mini-css-extract-plugin
export var productGreen = "products-module--product-green--327b9";
export var productOrange = "products-module--product-orange--a4e77";
export var productRed = "products-module--product-red--9a49b";
export var product__bottom = "products-module--product__bottom--f1520";
export var product__content = "products-module--product__content--ebb17";
export var product__currency = "products-module--product__currency--b8c40";
export var product__header = "products-module--product__header--4da61";
export var product__headerDescription = "products-module--product__header-description--6c371";
export var product__info = "products-module--product__info--39336";
export var product__price = "products-module--product__price--a15b8";
export var product__promoText = "products-module--product__promo-text--2dd53";
export var product__string = "products-module--product__string--5db75";
export var product__tag = "products-module--product__tag--2a7fd";
export var product__title = "products-module--product__title--6e98b";
export var product__top = "products-module--product__top--3429a";
export var products__button = "products-module--products__button--a621f";
export var products__content = "products-module--products__content--436cd";
export var products__decription = "products-module--products__decription--b53aa";
export var products__header = "products-module--products__header--02c84";
export var products__header__title = "products-module--products__header__title--a7045";
export var products__slider = "products-module--products__slider--5d3f2";
export var products__sliderHasDescription = "products-module--products__slider--has-description--8c44c";
export var products__slider__bottom__space = "products-module--products__slider__bottom__space--5a28d";