import {
  deleteJwt,
  getJwt,
  isTokenExpired,
  setJwt,
  setJwtRefresh,
  storeTokens,
} from '~/features/auth/by-cookie/jwt/model';

export const useJWTStorage = () => ({
  getJwt,
  setJwt,
  setJwtRefresh,
  isTokenExpired,
  deleteJwt,
  storeTokens,
});
