import React from 'react';

import { Container, Heading, Image } from '~/shared/components';
import { gatsbyImageType } from '~/widgets/sections/typed';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import * as styles from './promo-card.module.scss';

export const PromoCard = ({ image, title, description, className }) => (
  <Container className={clsx(className ? className : styles.promo)}>
    <Container className={styles.promo__image}>
      <Image objectFit="contain" src={image.imageFile} alt={title} />
    </Container>
    <Container className={styles.promo__content}>
      <Heading variant={3}>{title}</Heading>
      <p>{description}</p>
    </Container>
  </Container>
);

const PromoCardPropTypes = {
  image: gatsbyImageType,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

PromoCard.propTypes = PromoCardPropTypes;
