import React from 'react';

import { AdvantageImage, AdvantageParagraph, Container } from '~/shared/components';

import * as styles from './advantage.business.item.module.scss';

export const AdvantageBusinessItem = ({ type, title, description }) => {
  return (
    <Container className={styles.advantage}>
      <Container>
        <AdvantageImage iconType={type} className={styles.advantage__icon} />
      </Container>
      <Container>
        <AdvantageParagraph title={title} description={description} className={styles.advantage__description} needDot />
      </Container>
    </Container>
  );
};
