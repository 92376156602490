import { useInterval } from '~/shared';

/**
 * Prolongates the session by invoking the provided action at the specified interval.
 *
 * @param {function} action - The action to be invoked at the specified interval.
 * @param {number} interval - The interval at which the action should be invoked, defaults to 15000 milliseconds.
 * @return {null} Always returns null.
 */
export const ProlongateSession = ({ action, interval = 15000 }) => {
  useInterval(action, interval);
  return null;
};

ProlongateSession.defaultProps = {
  callback: () => {},
  interval: 15000,
};
