import React from 'react';

import { Button } from '~/shared/components';
import * as styles from '~/shared/components/ui/elements/slider/container.module.scss';
import clsx from 'clsx';

export const PrevArrowButton = React.memo(({ arrow, className, action }) => {
  return (
    <Button
      className={clsx(styles.slider__button, styles.slider__button__left, className)}
      onClick={() => action()}
      data-type={'prev'}
      title={''}
    >
      <img src={arrow} alt={'prev'} />
    </Button>
  );
});
export const NextArrowButton = React.memo(({ arrow, className, action }) => {
  return (
    <Button
      className={clsx(styles.slider__button, styles.slider__button__right, className)}
      onClick={() => action()}
      data-type={'next'}
      title={''}
    >
      <img style={{ rotate: '180deg' }} src={arrow} alt={'next'} />
    </Button>
  );
});
