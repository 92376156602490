import React from 'react';
import { useFormContext } from 'react-hook-form';

import '../animation.scss';
import { ErrorMessageWrap } from '~/shared/components/form/form-error';
import clsx from 'clsx';

import * as styles from './container.module.scss';

export const FormInput = React.forwardRef(
  ({ name, type = 'text', className = 'form__input', errorPerFieldEnabled = false, hint = null, ...rest }, ref) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    return (
      <>
        <input
          ref={ref}
          {...register(name, { required: true, maxLength: 20 })}
          type={type}
          {...rest}
          className={clsx(className)}
          autoComplete="off"
        />
        {hint ? <span className={'form__input--hint'}>{hint}</span> : <></>}
        <ErrorMessageWrap errors={errors} name={name} enabled={errorPerFieldEnabled} />
      </>
    );
  }
);

export const Textarea = React.forwardRef(
  ({ name, className = 'form__input', errorPerFieldEnabled = false, hint = null, ...rest }, ref) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    return (
      <>
        <textarea
          ref={ref}
          {...register(name, { required: true, maxLength: 20 })}
          {...rest}
          className={clsx(className, styles.textarea)}
          autoComplete="off"
        />
        {hint ? <span className={'form__input--hint'}>{hint}</span> : <></>}
        <ErrorMessageWrap errors={errors} name={name} enabled={errorPerFieldEnabled} />
      </>
    );
  }
);
