// extracted by mini-css-extract-plugin
export var newsletter = "newsletter-module--newsletter--2a7ab";
export var newsletter__button = "newsletter-module--newsletter__button--76b77";
export var newsletter__content = "newsletter-module--newsletter__content--04e3f";
export var newsletter__controls = "newsletter-module--newsletter__controls--3f45b";
export var newsletter__description = "newsletter-module--newsletter__description--5e388";
export var newsletter__form = "newsletter-module--newsletter__form--ab7ce";
export var newsletter__header = "newsletter-module--newsletter__header--4b083";
export var newsletter__headerSecondTitle = "newsletter-module--newsletter__header--second-title--b059a";
export var newsletter__headerTitle = "newsletter-module--newsletter__header--title--dae56";
export var newsletter__inputWrapper = "newsletter-module--newsletter__input-wrapper--3b6a2";