import { useMemo } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import get from 'lodash.get';

export const useSiteLoadSettings = () => {
  const { locale: language, defaultLocale: defaultLanguage } = useIntl();

  const {
    settingsEN,
    settingsDE,
    navigationThemenEN,
    navigationThemenDE,
    navigationFooterPagesEN,
    navigationFooterPagesDE,
    navigationMainEN,
    navigationMainDE,
    allAvailableMenus,
  } = useStaticQuery(graphql`
    query siteSettings {
      settingsEN: allStrapiGlobal(filter: { locale: { eq: "en" } }) {
        ...SiteSettingsEdges
      }
      settingsDE: allStrapiGlobal(filter: { locale: { eq: "de" } }) {
        ...SiteSettingsEdges
      }
      navigationThemenEN: allStrapiNavigation(filter: { name: { eq: "footerThemen" }, localeCode: { eq: "en" } }) {
        ...NavigationEdges
      }
      navigationThemenDE: allStrapiNavigation(filter: { name: { eq: "footerThemen" }, localeCode: { eq: "de" } }) {
        ...NavigationEdges
      }
      navigationFooterPagesEN: allStrapiNavigation(filter: { name: { eq: "footerPages" }, localeCode: { eq: "en" } }) {
        ...NavigationEdges
      }
      navigationFooterPagesDE: allStrapiNavigation(filter: { name: { eq: "footerPages" }, localeCode: { eq: "de" } }) {
        ...NavigationEdges
      }
      navigationMainEN: allStrapiNavigation(filter: { localeCode: { eq: "en" }, name: { eq: "main" } }) {
        ...NavigationEdges
      }
      navigationMainDE: allStrapiNavigation(filter: { localeCode: { eq: "de" }, name: { eq: "main" } }) {
        ...NavigationEdges
      }
      allAvailableMenus: allStrapiNavigation {
        ...NavigationEdges
      }
    }
  `);
  const settingsMap = useMemo(
    () => ({
      en: settingsEN,
      de: settingsDE,
    }),
    [settingsEN, settingsDE]
  );

  const footerThemenMenuMap = useMemo(
    () => ({
      en: navigationThemenEN,
      de: navigationThemenDE,
    }),
    [navigationThemenEN, navigationThemenDE]
  );
  const footerPagesMenuMap = useMemo(
    () => ({
      en: navigationFooterPagesEN,
      de: navigationFooterPagesDE,
    }),
    [navigationFooterPagesEN, navigationFooterPagesDE]
  );
  const mainMenuMap = useMemo(
    () => ({
      en: navigationMainEN,
      de: navigationMainDE,
    }),
    [navigationMainEN, navigationMainDE]
  );
  const menusPerPage = useMemo(() => {
    const menus = get(allAvailableMenus, 'edges', []);
    const flat = menus.map(({ node }) => ({ ...node }));
    const byLocale = flat.reduce((acc, { localeCode, ...menu }) => {
      if (Object.keys(acc).includes(localeCode)) {
        acc[localeCode] = [...acc[localeCode], menu];
      } else acc[localeCode] = [menu];
      return acc;
    }, {});
    return byLocale;
  }, [allAvailableMenus]);

  const settings = useMemo(() => {
    const settingsEdge = settingsMap?.[language] || settingsMap?.[defaultLanguage];
    return get(settingsEdge, 'edges[0].node', {});
  }, [settingsMap, language]);

  const navigation = useMemo(() => {
    return {
      main: mainMenuMap?.[language] || mainMenuMap?.[defaultLanguage],
      themen: footerThemenMenuMap?.[language] || footerThemenMenuMap?.[defaultLanguage],
      pages: footerPagesMenuMap?.[language] || footerPagesMenuMap?.[defaultLanguage],
      menusPerPage: menusPerPage?.[language] || [],
    };
  }, [footerThemenMenuMap, footerPagesMenuMap, mainMenuMap, language]);

  return {
    settings,
    navigation,
  };
};
