import React, { useMemo } from 'react';

import { Button } from '~/shared/components';
import { navigate } from 'gatsby-plugin-intl';

export const ButtonLink = React.memo(({ navigateTo, fallback, external = false, ...buttonProps }) => {
  const computeNavigateTo = useMemo(() => {
    if (navigateTo?.startsWith('/')) return navigateTo;
    return `/${navigateTo}`;
  }, [navigateTo]);

  if (navigateTo) return <Button {...buttonProps} onClick={() => navigate(computeNavigateTo)} />;
  return <Button {...buttonProps} onClick={() => navigate(fallback)} />;
});
