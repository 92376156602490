import { useMemo } from 'react';

import { format, parseISO } from 'date-fns';
import { de, enGB } from 'date-fns/locale';
import { useIntl } from 'gatsby-plugin-intl';

const locales = {
  en: enGB,
  de: de,
};

export const useDateFormatter = ({ rawDate, humanFormat }) => {
  const { locale } = useIntl();
  const date = useMemo(() => {
    try {
      return parseISO(rawDate);
    } catch (e) {
      return new Date();
    }
  }, [rawDate, humanFormat]);
  return format(date, humanFormat, { locale: locales[locale] });
};
