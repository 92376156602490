import React from 'react';

import { ButtonLinkOnlyNotLoggedIn } from '~/features/auth';
import { Container, Heading, IF, Image, Section } from '~/shared/components';

import * as styles from './promo.cards.module.scss';

export const PromoCardsBusinessSection = ({ blockTitle, promotions: items, button }) => {
  const renderItems = items.map(({ image, title, description }) => (
    <Container key={title} className={styles.promoCards__content__cards__card}>
      <Container className={styles.promoCards__content__cards__cardImage}>
        <Image objectFit="contain" src={image.imageFile} alt={title} />
      </Container>
      <Heading className={styles.promoCards__content__cards__cardTitle} variant={3}>
        {title}
      </Heading>
      <p className={styles.promoCards__content__cards__cardDescription}>{description}</p>
    </Container>
  ));
  return (
    <Section title={blockTitle}>
      <Container className={styles.promoCards__content}>
        <Container className={styles.promoCards__content__cards}>{renderItems}</Container>
        <Container className={styles.promoCards__content__button}>
          <IF condition={button}>
            <ButtonLinkOnlyNotLoggedIn
              id={'promo-cards-business-section-button'}
              navigateTo={button.page?.slug}
              fallback={button?.buttonLink}
              title={button?.buttonText}
              variant={button?.buttonVariant}
            />
          </IF>
        </Container>
      </Container>
    </Section>
  );
};
