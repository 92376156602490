import { useContext, useSyncExternalStore } from 'react';

/**
 * Custom hook for accessing and managing authentication store state.
 *
 * @param {Object} context - The context to use for accessing the store.
 * @param {Function} selector - The function to select the portion of the store state to return.
 * @return {Array} An array containing the selected state and the function to update the store state.
 */
export const useStore = (context, selector) => {
  const store = useContext(context);
  if (!store) {
    throw 'Store not found';
  }

  const state = useSyncExternalStore(
    store.subscribe,
    () => selector(store.get()),
    () => undefined
  );
  return [state, store.set];
};
