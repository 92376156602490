import React from 'react';

import { ExternalLinkComponent } from '~/shared/components';
import * as styles from '~/widgets/header/header.module.scss';
import clsx from 'clsx';
import { Link } from 'gatsby-plugin-intl';

export const MenuNavigation = ({ children, type = 'desktop' }) => (
  <nav className={clsx(type === 'desktop' ? styles.header__desktopMenu : '')}>
    <ul className={styles.header__list}>{children}</ul>
  </nav>
);

export const MenuNavigationLink = React.memo(({ title, link, target, external = false, handleBubbling = () => {} }) => {
  const LinkComponent = external ? ExternalLinkComponent : Link;
  return (
    <li onClick={handleBubbling}>
      <LinkComponent activeClassName="active" target={target} to={link}>
        {title}
      </LinkComponent>
    </li>
  );
});
