import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { ReactComponent as Facebook } from '~/assets/images/icons/social/facebook.svg';
import { ReactComponent as Tweeter } from '~/assets/images/icons/social/twitter.svg';
import { Button, Container, Heading, Section } from '~/shared/components';
import { useIntl } from 'gatsby-plugin-intl';

import * as styles from './share.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const ShareUsForBlogPostSection = ({ url, meta: { metaTitle }, shareText }) => {
  const { formatMessage } = useIntl();
  return (
    <Section title={'share-us'}>
      <Container className="wrapper-full-mobile">
        <Container className={styles.shareUs__content}>
          <Container className={styles.shareUs__action}>
            <Heading variant={3}>{shareText}</Heading>
            <Container className={styles.shareUs__actionList}>
              <FacebookShareButton url={url}>
                <Button title={formatMessage({ id: 'share.fb.label' })} variant={'border-blue'} size={'medium'}>
                  <Facebook fill={'red'} />
                </Button>
              </FacebookShareButton>
              <TwitterShareButton url={url} title={metaTitle}>
                <Button title={formatMessage({ id: 'share.tw/x.label' })} variant={'border-light-blue'} size={'medium'}>
                  <Tweeter />
                </Button>
              </TwitterShareButton>
            </Container>
          </Container>
        </Container>
      </Container>
    </Section>
  );
};

ShareUsForBlogPostSection.defaultProps = {
  shareTextKey: 'share.blog.label',
};
