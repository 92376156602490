import React from 'react';

import { Container, Heading, Section } from '~/shared/components';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import * as styles from './mission.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const OurMissionSection = ({ blockTitle, title, description }) => {
  return (
    <Section title={blockTitle} className={clsx(styles.mission__contentSection, 'full-width')}>
      <Container className={styles.mission__content}>
        <Heading variant={2}>{title ? title : blockTitle}</Heading>
        <p>{description}</p>
        <span className={clsx(styles.mission__blob, styles.mission__blob_left)}></span>
        <span className={clsx(styles.mission__blob, styles.mission__blob_right)}></span>
      </Container>
    </Section>
  );
};

OurMissionSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
