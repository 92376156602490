import React from 'react';

import { Container, Heading, ListElement, Section } from '~/shared/components';
import { baseSectionType } from '~/widgets/sections/typed';
import PropTypes from 'prop-types';

import * as styles from './list.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const ListOfPointsSection = ({ blockTitle, title, points }) => (
  <Section title={blockTitle} className={'breakout'}>
    <Container className={styles.list__wrapper}>
      <Heading variant={4} className={styles.list__title}>
        {title ? title : blockTitle}
      </Heading>
      <Container>
        <ul className={styles.list}>
          {points.map((point) => (
            <ListElement key={point.text} text={point.text} />
          ))}
        </ul>
      </Container>
    </Container>
  </Section>
);

ListOfPointsSection.propTypes = {
  baseSectionType,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ),
};
