export const buttonVariants = [
  'orange',
  'green',
  'transparent',
  'border-blue',
  'border-lite-blue',
  'pagination',
  'invisible',
];
export const buttonSizes = ['small', 'large', 'medium'];
