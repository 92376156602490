import { businessSections } from '~/widgets/sections/business.sections';
import { commonSection, NoopComponent } from '~/widgets/sections/common.sections';

const __noop = '___noop__';

const Sections = {
  [__noop]: NoopComponent,
  ...commonSection,
  ...businessSections,
};

export const handleSection = (typename) => {
  return Sections[typename] || Sections[__noop];
};
