import React, { useMemo } from 'react';

import Logo from '~/assets/images/logo/logo-white.svg';
import { useSiteNavigation, useSiteSettings } from '~/shared';
import { useFooterMenuItems } from '~/shared';
import { Container, Footer, Heading, Image, MenuNavigationLink } from '~/shared/components';
import { FooterContact, FooterSocial } from '~/widgets/footer/ui';
import clsx from 'clsx';
import { Link, useIntl } from 'gatsby-plugin-intl';

import * as styles from './footer.module.scss';

const ContactsBlock = ({ contacts }) => {
  const available = useMemo(() => contacts && contacts.length > 0, [contacts]);

  const { formatMessage } = useIntl();

  const renderContacts = useMemo(
    () => contacts?.map((contact) => <FooterContact key={contact.contactInfo} {...contact} />),
    [contacts]
  );

  if (!available) return <></>;

  return (
    <Container className={styles.footer__contacts}>
      <Heading variant={5}>{formatMessage({ id: 'footer.contact.label' })}</Heading>
      {renderContacts}
    </Container>
  );
};
const SocialLinksBlock = ({ socialLinks }) => {
  const available = useMemo(() => socialLinks && socialLinks.length > 0, [socialLinks]);

  const renderSocial = useMemo(
    () => socialLinks?.map((social) => <FooterSocial key={social.social} {...social} />),
    [socialLinks]
  );
  if (!available) return <></>;

  return <Container className={styles.footer__social}>{renderSocial}</Container>;
};

const FooterImage = ({ siteLogo }) => {
  let LogoImage = <img src={Logo} alt={'pinga!'} />;
  if (siteLogo?.picture.localFile && siteLogo?.picture.localFile.childImageSharp) {
    LogoImage = <Image src={siteLogo.picture.localFile} alt={siteLogo.altText} />;
  }
  return (
    <Link className={styles.footer__logoLink} to={'/'}>
      {LogoImage}
    </Link>
  );
};

export const FooterSection = ({
  contactBlock: ContactBlock = ContactsBlock,
  socialBlock: SocialLinks = SocialLinksBlock,
}) => {
  const settings = useSiteSettings();
  const { themen, pages } = useSiteNavigation();
  const { themenMenuItems, pagesMenuItems } = useFooterMenuItems({ themen, pages });
  const { legalInformation, footerLogo, copyright, contacts, socialLinks } = settings;
  const { formatMessage } = useIntl();
  const hasThemen = themenMenuItems.length > 0;
  const renderThemenMenu = useMemo(
    () => themenMenuItems?.map((link) => <MenuNavigationLink key={link.title} {...link} />),
    [themenMenuItems]
  );
  const renderPagesMenu = useMemo(
    () => pagesMenuItems?.map((link) => <MenuNavigationLink key={link.title} {...link} />),
    [pagesMenuItems]
  );

  return (
    <Footer title={'footer'} className={clsx(styles.footer, 'content-grid')}>
      <Container className={clsx(styles.footer__content)}>
        <FooterImage siteLogo={footerLogo} />
        <Container className={styles.footer__columns}>
          <Container className={styles.footer__addres}>
            <p>{legalInformation}</p>
          </Container>
          <ContactBlock contacts={contacts} />
          <Container className={styles.footer__pages}>
            {hasThemen ? <h5>{formatMessage({ id: 'footer.themen.label' })}</h5> : <></>}
            <ul className={styles.footer__listPages}>{renderThemenMenu}</ul>
          </Container>
          <Container className={styles.footer__links}>
            <ul className={styles.footer__listLinks}>{renderPagesMenu}</ul>
            <SocialLinks socialLinks={socialLinks} />
          </Container>
        </Container>
        <Container className={styles.footer__copyright}>{copyright}</Container>
      </Container>
    </Footer>
  );
};
