import React from 'react';

import { LAYOUTS } from '~/shared';
import { ExternalLayout, PublicLayout } from '~/widgets/layout';

const LAYOUTS_COMPONENTS = {
  [LAYOUTS.PUBLIC_LAYOUT]: PublicLayout,
  [LAYOUTS.EXTERNAL_LAYOUT]: ExternalLayout,
  [LAYOUTS.NOT_FOUND_LAYOUT]: ExternalLayout,
  __default__: PublicLayout,
};

export default ({ children, pageContext, location }) => {
  const Layout = LAYOUTS_COMPONENTS?.[pageContext.layout] || LAYOUTS_COMPONENTS['__default__'];
  return (
    <Layout location={location} pageContext={pageContext}>
      {children}
    </Layout>
  );
};
