// extracted by mini-css-extract-plugin
export var bottom__space = "container-module--bottom__space--918a5";
export var left__space = "container-module--left__space--13816";
export var navigation__disabled = "container-module--navigation__disabled--2313f";
export var right__space = "container-module--right__space--ca6e1";
export var slider = "container-module--slider--e4f9e";
export var slider__button = "container-module--slider__button--12420";
export var slider__button__left = "container-module--slider__button__left--95ca7";
export var slider__button__right = "container-module--slider__button__right--ba448";
export var swiper__button__next = "container-module--swiper__button__next--d1ab0";
export var swiper__button__prev = "container-module--swiper__button__prev--328c6";