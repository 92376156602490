import React from 'react';

import { buttonSizes, buttonVariants } from '~/shared/components/ui/elements/button/typed';
import clsx from 'clsx';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';

import * as styles from './button.module.scss';

/**
 * @type React.ForwardRefRenderFunction<HTMLButtonElement, ButtonPropTypes>
 */
export const Button = React.forwardRef(
  (
    {
      variant,
      title,
      className,
      classNameExtended,
      type = 'button',
      onClick = () => {},
      disabled,
      size = 'regular',
      children,
      ...rest
    },
    ref
  ) => (
    <button
      ref={ref}
      type={type}
      {...rest}
      className={
        className
          ? className
          : clsx(
              styles.button,
              styles[camelCase(`button-${variant}`)],
              styles[camelCase(`button-${size}`)],
              classNameExtended && classNameExtended
            )
      }
      onClick={onClick}
      disabled={disabled}
    >
      <>
        {children}
        {title}
      </>
    </button>
  )
);

const ButtonPropTypes = {
  variant: PropTypes.oneOf(buttonVariants),
  size: PropTypes.oneOf(buttonSizes),
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.propTypes = ButtonPropTypes;
