import { api, provideJWTHeader } from '~/shared/api';
import Cookies from 'js-cookie';
import { jwtDecode as decode } from 'jwt-decode';

const JWT_STORAGE_KEY = 'accessToken';
const JWT_REFRESH_STORAGE_KEY = 'refreshToken';

export const getJwt = () => {
  if (typeof window === `undefined`) return undefined;
  return Cookies.get(JWT_STORAGE_KEY);
};
export const getJwtRefresh = () => {
  if (typeof window === `undefined`) return undefined;
  return Cookies.get(JWT_REFRESH_STORAGE_KEY);
};
export const setJwt = (jwt) => Cookies.set(JWT_STORAGE_KEY, jwt);
export const setJwtRefresh = (jwt) => Cookies.set(JWT_REFRESH_STORAGE_KEY, jwt);

export const deleteJwt = () => {
  if (typeof window === `undefined`) return undefined;
  Cookies.delete(JWT_STORAGE_KEY);
  Cookies.delete(JWT_REFRESH_STORAGE_KEY);
  provideJWTHeader(null);
};

export const isTokenExpired = (token) => (token ? decode(token).exp <= Date.now() / 1000 : true);

export const storeTokens = ({ accessToken, refreshToken }) => {
  setJwt(accessToken);
  setJwtRefresh(refreshToken);
  return Promise.resolve();
};

export const refreshTokenAction = async (refreshToken) => {
  return await api.post(`/customers/authorization/refresh`, {
    refreshToken: refreshToken,
  });
};
