// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--c2808";
export var footer__addres = "footer-module--footer__addres--cf4fc";
export var footer__columns = "footer-module--footer__columns--7d675";
export var footer__contacts = "footer-module--footer__contacts--56dc6";
export var footer__contactsLink = "footer-module--footer__contacts-link--e9c64";
export var footer__content = "footer-module--footer__content--c63ee";
export var footer__copyright = "footer-module--footer__copyright--3ead8";
export var footer__links = "footer-module--footer__links--18f56";
export var footer__listLinks = "footer-module--footer__list-links--b9586";
export var footer__listPages = "footer-module--footer__list-pages--93c35";
export var footer__logo = "footer-module--footer__logo--28c37";
export var footer__logoLink = "footer-module--footer__logo-link--d106f";
export var footer__pages = "footer-module--footer__pages--4e3f1";
export var footer__social = "footer-module--footer__social--844c4";