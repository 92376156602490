import React from 'react';

import { Container, Heading, Image, Section } from '~/shared/components';
import clsx from 'clsx';

import * as styles from './benefits.module.scss';

const BenefitsSectionTop = ({ blockTitle, image, firstTextBlock }) => {
  return (
    <Section title={blockTitle} className={styles.benefitsSection}>
      <Container className={styles.benefits}>
        <Container className={clsx(styles.benefitsBlock, styles.benefitsBlock__first)}>
          <Heading variant={2}>{firstTextBlock.title}</Heading>
          <p>{firstTextBlock.description}</p>
        </Container>
        <Container className={clsx(styles.benefitsBlock, styles.benefitsBlockImage)}>
          <Container className={styles.benefitsBlockImage__container}>
            <Container className={styles.benefitsBlockImage__translated} />
            <Image objectFit="contain" src={image.imageFile} alt={blockTitle} />
          </Container>
        </Container>
      </Container>
    </Section>
  );
};

const BenefitsSectionBottom = ({ blockTitle, secondTextBlock, thirdTextBlock }) => {
  return (
    <Section title={blockTitle}>
      <Container className={styles.benefits__bottom}>
        <Container className={clsx(styles.benefits__bottomBlock, styles.benefitsBlock__heading)}>
          <Container className={styles.benefits__bottom__secondBackground} />
          <Heading variant={3} className={styles.benefits__bottomBlock__titleBlock}>
            {secondTextBlock.title}
          </Heading>
          <p>{secondTextBlock.description}</p>
        </Container>
        <Container className={clsx(styles.benefits__bottomBlock, styles.benefitsBlock__heading)}>
          <Container className={styles.benefits__bottom__thirdBackground} />
          <Heading variant={3} className={styles.benefits__bottomBlock__title}>
            {thirdTextBlock.title}
          </Heading>
          <p>{thirdTextBlock.description}</p>
        </Container>
      </Container>
    </Section>
  );
};

export const BenefitsSection = ({ blockTitle, image, firstTextBlock, secondTextBlock, thirdTextBlock }) => {
  return (
    <>
      <BenefitsSectionTop blockTitle={blockTitle} image={image} firstTextBlock={firstTextBlock} />
      <BenefitsSectionBottom
        blockTitle={'BenefitsSectionBottom'}
        secondTextBlock={secondTextBlock}
        thirdTextBlock={thirdTextBlock}
      />
    </>
  );
};
