import React, { useMemo } from 'react';

import { Container, Heading, Section } from '~/shared/components';
import PropTypes from 'prop-types';

import * as styles from './statistic.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const StatisticPingaSection = React.memo(({ blockTitle, title, items }) => {
  const renderStatisticsItems = useMemo(
    () =>
      items.map(({ measure, explanation }) => (
        <Container key={measure} className={styles.statistic__block}>
          <Heading variant={2}>{measure}</Heading>
          <p>{explanation}</p>
        </Container>
      )),
    [items]
  );
  return (
    <Section title={blockTitle} className={styles.statistic}>
      <Container className={styles.statistic__content}>{renderStatisticsItems}</Container>
    </Section>
  );
});

StatisticPingaSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      measure: PropTypes.string.isRequired,
      explanation: PropTypes.string.isRequired,
    })
  ),
};
