import React from 'react';

import ProfilePic from '~/assets/images/icons/header/profile.svg';
import { Container } from '~/shared/components';
import clsx from 'clsx';

import * as styles from './profile.module.scss';

export const ProfileButton = ({ className, onClick, pictureAlt, profilePicture: Picture = ProfilePic }) => (
  <Container className={styles.profile__container} onClick={onClick}>
    <img className={clsx(styles.profile, className)} src={Picture} alt={pictureAlt} />
  </Container>
);

ProfileButton.defaultProps = {
  className: '',
  pictureAlt: '',
  onClick: () => {},
  profilePicture: ProfilePic,
};
