import React from 'react';

import { graphql } from 'gatsby';

// eslint-disable-next-line react/display-name
export default () => {
  return <></>;
};

export const query = graphql`
  fragment SeoFragment on STRAPI__COMPONENT_SHARED_SEO {
    __typename
    id
    metaTitle
    metaDescription
    metaImage {
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.77, quality: 100)
        }
      }
    }
    keywords
    canonicalURL
    metaRobots
    metaSocial {
      title
      socialNetwork
      description
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.77, quality: 100)
          }
        }
      }
    }
  }
  fragment PageSectionsFragment on STRAPI_SECTION {
    __typename
    title
    id
    subTitle
    block {
      __typename
      ... on ComponentPageBlocksWelcomSection {
        id
        mainImage {
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 1400
                placeholder: BLURRED
                blurredOptions: { width: 350 }
                formats: [AUTO, JPG]
                quality: 100
              )
            }
          }
        }
        mainMobileImage {
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                blurredOptions: { width: 20 }
                formats: [PNG]
                quality: 95
              )
            }
          }
        }
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksSupportSection {
        id
        supportBlock {
          description
          title
        }
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksAdvantagesSection {
        id
        image {
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 940, placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
            }
          }
        }
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
        items {
          description
          title
          id
          iconType
        }
      }
      ... on ComponentPageBlocksMarkdownSection {
        content
        contentImages {
          id
          url
          childImageSharp {
            gatsbyImageData(aspectRatio: 1, formats: [AUTO, WEBP], quality: 100, placeholder: BLURRED)
          }
        }
      }
      ... on ComponentPageBlocksFaqSection {
        items {
          question
          answer
        }
      }

      ... on ComponentPageBlocksTeamSection {
        teammates {
          image {
            imageFile {
              childImageSharp {
                gatsbyImageData(quality: 100, formats: [JPG], placeholder: BLURRED)
              }
            }
          }
          name
          specialization
        }
        scientific {
          image {
            imageFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1, quality: 100)
              }
            }
          }
          name
          description
        }
      }
      ... on ComponentPageBlocksListOfPointsSection {
        points {
          text
        }
      }
      ... on ComponentPageBlocksPartnersSection {
        logo {
          imageFile {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, PNG], width: 915, quality: 100, placeholder: BLURRED)
            }
          }
        }
      }
      ... on ComponentPageBlocksOurMissionSection {
        description
      }
      ... on ComponentPageBlocksWorkInfoSection {
        image {
          imageFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1, quality: 100, placeholder: BLURRED)
            }
          }
        }
        works {
          text
        }
      }
      ... on ComponentPageBlocksReviewSection {
        items {
          author
          review
        }
      }
      ... on ComponentPageBlocksAboutSection {
        title
        description
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksPsychologistSection {
        benefits {
          benefit
        }
        psychologist {
          psychologistName
          grade
          psychologistImageFile {
            childImageSharp {
              gatsbyImageData(width: 336, height: 336, quality: 100, placeholder: BLURRED)
            }
          }
          licenseImageFile {
            childImageSharp {
              gatsbyImageData(width: 94, height: 94, quality: 100, placeholder: BLURRED)
            }
          }
          line {
            lineName
            description
          }
        }
      }
      ... on ComponentPageBlocksSponsorsSection {
        title
        image {
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 735, placeholder: BLURRED, formats: [AUTO, WEBP, PNG], quality: 100)
            }
          }
        }
      }
      ... on ComponentPageBlocksThemesSection {
        themen {
          theme
          explanation
        }
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksThemesSectionAccordion {
        themen {
          theme
          explanation
        }
      }
      ... on ComponentPageBlocksBlogSection {
        numberOfPosts
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksPromoSection {
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
        promoCards {
          image {
            imageFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.77, quality: 100, placeholder: BLURRED)
              }
            }
          }
          title
          description
        }
      }
      ... on ComponentPageBlocksNewsletterSection {
        richText {
          content
          contentImages {
            id
            url
            childImageSharp {
              gatsbyImageData(width: 470, formats: [AUTO, WEBP], quality: 100, placeholder: BLURRED)
            }
          }
        }
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksProductsSection {
        products {
          productName
          productMetaTag
          productPrice
          productDuration
          productDescription {
            advantage
          }
          productPromoText
        }
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksFullWidthBannerSection {
        bannerTitle
        bannerSubTitle
        variant
        bannerImage {
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 5000, quality: 100)
            }
          }
        }
        bannerImageMobile {
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], layout: FULL_WIDTH, quality: 100)
            }
          }
        }
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksContactUsSection {
        contactText
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksContactUsFormSection {
        formName
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
        successTitle
        successDescription
        successButton {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksDemoFormBusinessSection {
        formName
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
        successTitle
        successDescription
        successButton {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksSuicideBannerSection {
        bannerText {
          content
          contentImages {
            id
            url
            childImageSharp {
              gatsbyImageData(width: 470, placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
            }
          }
        }
        bannerTitle
      }
      ... on ComponentPageBlocksStatisticsSection {
        items {
          measure
          explanation
        }
      }
      ... on ComponentPageBlocksPromoAdvantages {
        items {
          advantage
          icon
        }
      }
      ... on ComponentPageBlocksTestStartedButtonSection {
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
      }
      ... on ComponentPageBlocksShareSection {
        shareText
      }
      ... on ComponentPageBlocksAboutUsHeaderSection {
        backgroundImage {
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, JPG], width: 5728, quality: 100)
              fluid(quality: 100) {
                src
              }
            }
          }
        }
        backgroundImageMobile {
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, JPG], width: 640, quality: 100)
              fluid(maxWidth: 769, quality: 100, base64Width: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
      }
      ... on ComponentPageBlocksAdvantagesBusinessSection {
        id
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
        items {
          description
          title
          id
          iconType
        }
      }
      ... on ComponentPageBlocksPromoBusinessSection {
        id
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
        promotions {
          image {
            imageFile {
              childImageSharp {
                gatsbyImageData(quality: 98, formats: [AUTO, JPG], placeholder: BLURRED, width: 600)
              }
            }
          }
          title
          description
        }
      }
      ... on ComponentPageBlocksBusinessPsychologistSection {
        items {
          text
        }
      }
      ... on ComponentPageBlocksBusinessBenefitsSection {
        image {
          imageFile {
            childImageSharp {
              gatsbyImageData(quality: 100, formats: [AUTO, PNG], placeholder: BLURRED)
            }
          }
        }
        firstTextBlock {
          title
          description
        }
        secondTextBlock {
          title
          description
        }
        thirdTextBlock {
          title
          description
        }
      }
      ... on ComponentPageBlocksPartnersBusinessSection {
        button {
          buttonLink
          buttonText
          buttonVariant
          page {
            slug
          }
        }
        items {
          description
          image {
            imageFile {
              childImageSharp {
                gatsbyImageData(quality: 98, formats: [AUTO, JPG], placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
  fragment NavigationEdges on StrapiNavigationConnection {
    edges {
      node {
        name
        slug
        localeCode
        pages {
          slug
        }
        items {
          title
          type
          path
          externalPath
          uiRouterKey
          related {
            __typename
            title
            slug
            _xcontentType
            navigationItemId
          }
          items {
            path
            type
            externalPath
            uiRouterKey
            type
          }
        }
      }
    }
  }
  fragment PostsEdges on STRAPI_BLOG_POSTConnection {
    edges {
      node {
        title
        slug
        publishedAt
        postPreviewText
        postCategory {
          title
          slug
        }
        blogPreview {
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
  fragment SiteSettingsEdges on STRAPI_GLOBALConnection {
    edges {
      node {
        legalInformation
        copyright
        socialLinks {
          social
          socialLink
        }
        contacts {
          contactType
          contactLabel
          contactInfo
        }
        locale
        footerLogo {
          altText
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 85, layout: FIXED, quality: 100)
              }
            }
          }
        }
        siteLogo {
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 85, layout: FIXED, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`;
