import * as SECTIONS from '~/shared/api/strapi';
import { AboutUsBanner } from '~/widgets/sections/common.sections/about-us-banner';
import { AboutUsHeaderSection } from '~/widgets/sections/common.sections/about-us-header';
import { AdvantagesSection } from '~/widgets/sections/common.sections/advantages';
import { BlogSection } from '~/widgets/sections/common.sections/blog';
import { ContactUsSection } from '~/widgets/sections/common.sections/contact-us';
import { ContactUsFormSection } from '~/widgets/sections/common.sections/contact-us-form';
import { FaqSection } from '~/widgets/sections/common.sections/faq';
import { FullWidthBanner } from '~/widgets/sections/common.sections/full-width-banner';
import { ListOfPointsSection } from '~/widgets/sections/common.sections/list-of-points';
import { MarkdownSection } from '~/widgets/sections/common.sections/markdown';
import { NewsletterSection } from '~/widgets/sections/common.sections/newsletter';
import { OurMissionSection } from '~/widgets/sections/common.sections/our-mission';
import { PartnersSection } from '~/widgets/sections/common.sections/partners';
import { ProductsSection } from '~/widgets/sections/common.sections/products';
import { PromoAdvantageSection } from '~/widgets/sections/common.sections/promo-green';
import { PromoSection } from '~/widgets/sections/common.sections/promo-section';
import { PsychologistSection } from '~/widgets/sections/common.sections/psychologist';
import { ReviewSection } from '~/widgets/sections/common.sections/reviews';
import { ShareUsForBlogPostSection } from '~/widgets/sections/common.sections/share-us';
import { SponsorsSection } from '~/widgets/sections/common.sections/sponsors';
import { StatisticPingaSection } from '~/widgets/sections/common.sections/statistic';
import { SuicideBannerSection } from '~/widgets/sections/common.sections/suicide-banner';
import { SupportSection } from '~/widgets/sections/common.sections/support';
import { TeamSection } from '~/widgets/sections/common.sections/team';
import { TestNavigationSection } from '~/widgets/sections/common.sections/test-navigation';
import { ThemesSection } from '~/widgets/sections/common.sections/themes';
import { ThemesAccordionSection } from '~/widgets/sections/common.sections/themes-accordion';
import { WelcomeSection } from '~/widgets/sections/common.sections/welcome';
import { WorkInfoSection } from '~/widgets/sections/common.sections/workinfo';

export const commonSection = {
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_WELCOME_SECTION]: WelcomeSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_SUPPORT_SECTION]: SupportSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_ADVANTAGES_SECTION]: AdvantagesSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_MARKDOWN_SECTION]: MarkdownSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_FAQ_SECTION]: FaqSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_REVIEW_SECTION]: ReviewSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_PSYCHOLOGIST_SECTION]: PsychologistSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_ABOUT_SECTION]: AboutUsBanner,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_SPONSORS_SECTION]: SponsorsSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_THEMES_SECTION]: ThemesSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_THEMES_SECTION_ACCORDION]: ThemesAccordionSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_BLOG_SECTION]: BlogSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_PRODUCTS_SECTION]: ProductsSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_NEWSLETTER_SECTION]: NewsletterSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_FULL_WIDTH_BANNER_SECTION]: FullWidthBanner,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_CONTACT_US_SECTION]: ContactUsSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_SUICIDE_BANNER_SECTION]: SuicideBannerSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_PINGA_STATISTICS_SECTION]: StatisticPingaSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_PROMO_ADVANTAGES_SECTION]: PromoAdvantageSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_TEST_BUTTON_SECTION]: TestNavigationSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_TEAM_SECTION]: TeamSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_ABOUT_US_HEADER_SECTION]: AboutUsHeaderSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_OUR_MISSION_SECTION]: OurMissionSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_WORK_INFO_SECTION]: WorkInfoSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_PROMO_SECTION]: PromoSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_CONTACT_US_FORM_SECTION]: ContactUsFormSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_PARTNERS_SECTION]: PartnersSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_LIST_OF_POINTS_SECTION]: ListOfPointsSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_SHARE_SECTION]: ShareUsForBlogPostSection,
};

export { NoopComponent } from '~/widgets/sections/common.sections/noop';
export { WelcomeSection } from '~/widgets/sections/common.sections/welcome';
export { MarkDownPost, Post } from '~/widgets/sections/common.sections/blog';
