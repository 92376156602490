import React from 'react';

import { useDateFormatter } from '~/shared';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import * as styles from './date.module.scss';

/**
 * @type React.ForwardRefRenderFunction<HTMLButtonElement, ButtonPropTypes>
 */
const BLOG_DATE_TIME_FORMAT = 'dd MMM yyyy';
export const PostDate = React.forwardRef(({ postDate, className }, ref) => {
  const formattedDate = useDateFormatter({ rawDate: postDate, humanFormat: BLOG_DATE_TIME_FORMAT });
  return (
    <span ref={ref} className={clsx(styles.date__row, className)}>
      {formattedDate}
    </span>
  );
});

const PostDateTypes = {
  postDate: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PostDate.propTypes = PostDateTypes;
