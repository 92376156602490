import React, { useMemo } from 'react';

import { ButtonLinkOnlyNotLoggedIn } from '~/features/auth';
import { Container, Heading, Image, Section } from '~/shared/components';
import clsx from 'clsx';
import isEmpty from 'lodash.isempty';

import * as styles from './fw-banner.module.scss';

const MobileImage = React.memo(({ hasMobile, bannerImageMobile }) => {
  if (!hasMobile) return <></>;

  return (
    <Image
      objectFit="cover"
      loading={'eager'}
      src={bannerImageMobile?.imageFile}
      alt={bannerImageMobile?.altText}
      className={clsx(styles.banner__contentBackground, hasMobile && 'mobile')}
    />
  );
});

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const FullWidthBanner = React.memo(
  ({ blockTitle, bannerTitle, bannerSubTitle, variant, button, bannerImage, bannerImageMobile = null }) => {
    const hasMobile = useMemo(() => !isEmpty(bannerImageMobile), [bannerImageMobile]);
    return (
      <Section title={blockTitle} className={clsx('full-width', styles.banner)}>
        <Container
          className={clsx(styles.banner__content, styles[`banner__contentVariants__${variant}`], 'full-width')}
        >
          <Image
            objectFit="cover"
            src={bannerImage?.imageFile}
            alt={bannerImage?.altText}
            loading={'eager'}
            className={clsx(styles.banner__contentBackground, hasMobile && 'desktop')}
          />
          <MobileImage hasMobile={hasMobile} bannerImageMobile={bannerImageMobile} />
          <Container className={clsx(styles.banner__gradient, styles[`banner__gradientVariants__${variant}`])} />
          <Container className={clsx(styles.banner__block)}>
            <Heading variant={1}>{bannerTitle}</Heading>
            <p>{bannerSubTitle}</p>
            <ButtonLinkOnlyNotLoggedIn
              id={'banner-section-button'}
              navigateTo={button.page?.slug}
              fallback={button.buttonLink}
              title={button.buttonText}
              variant={button.buttonVariant}
              size={'medium'}
            />
          </Container>
        </Container>
      </Section>
    );
  }
);
