import React, { useCallback, useMemo } from 'react';

import CheckedOrange from '~/assets/images/icons/products/check-orange.svg';
import Star from '~/assets/images/icons/products/star.svg';
import { ButtonLinkOnlyNotLoggedIn, useJwtAuth } from '~/features/auth';
import { useCurrency } from '~/shared';
import { Container, Heading, IF, Section, SlideContainer, Slider } from '~/shared/components';
import { useColor } from '~/widgets/sections/common.sections/products/hooks/useColor';
import { baseSectionType, buttonType } from '~/widgets/sections/typed';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { SwiperSlide } from 'swiper/react';

import * as styles from './products.module.scss';

const productType = PropTypes.shape({
  productName: PropTypes.string.isRequired,
  productMetaTag: PropTypes.string,
  productPrice: PropTypes.number.isRequired,
  productDuration: PropTypes.number.isRequired,
  productDescription: PropTypes.arrayOf(
    PropTypes.shape({
      advantage: PropTypes.string,
    })
  ),
  productPromoText: PropTypes.string,
});

const ProductDescriptionBlock = ({ productDescription: productAdvantages }) => {
  const hasAdvantages = useMemo(() => productAdvantages?.length > 0, [productAdvantages]);
  const starredAdvantage = useMemo(() => productAdvantages.slice(0, 1)?.[0] || null, [productAdvantages]);
  const otherAdvantage = useMemo(() => productAdvantages.slice(1, productAdvantages.length) || [], [productAdvantages]);
  if (!hasAdvantages) return <></>;

  const renderStarredAdvantage = useMemo(
    () => (
      <Container className={styles.product__string}>
        <img src={Star} alt={'stars'} />
        <span>{starredAdvantage.advantage}</span>
      </Container>
    ),
    [starredAdvantage]
  );

  return (
    <>
      {renderStarredAdvantage}
      <Container className={''}>
        <ul>
          {otherAdvantage.map((advantage) => (
            <li className={styles.product__string} key={advantage.advantage}>
              <img src={CheckedOrange} alt={'Checked'} />
              <span>{advantage.advantage}</span>
            </li>
          ))}
        </ul>
      </Container>
    </>
  );
};

const ProductItem = ({ product, color, productDescription: ProductDescription = ProductDescriptionBlock }) => {
  const { code } = useCurrency();
  const { formatMessage } = useIntl();
  const renderDurationInfo = useCallback(
    (duration) => formatMessage({ id: 'section.products.priceDuration' }, { duration }),
    [product]
  );
  return (
    <SlideContainer className={styles.product__content}>
      <Container className={clsx(styles.product__top, styles[camelCase(`product-${color}`)])}>
        <Container className={styles.product__header}>
          <span className={styles.product__tag}>{product.productMetaTag}</span>
          <span className={styles.product__title}>{product.productName}</span>
        </Container>
        <Container className={styles.product__headerDescription}>
          <span className={styles.product__currency}>{code}</span>
          <span className={styles.product__price}>{product.productPrice}</span>
          <span className={styles.product__info}>{renderDurationInfo(product.productDuration)}</span>
        </Container>
        <p className={styles.product__promoText}>{product.productPromoText}</p>
      </Container>
      <Container className={styles.product__bottom}>
        <ProductDescription productDescription={product.productDescription} />
      </Container>
    </SlideContainer>
  );
};

ProductItem.propTypes = {
  product: productType,
};

const productColors = ['red', 'green', 'orange'];

const breakPoints = {
  0: {
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      enabled: true,
    },
    navigation: {
      enabled: false,
      hidden: true,
    },
  },
  800: {
    slidesPerView: 2,
    spaceBetween: 30,
    pagination: {
      enabled: true,
    },
    navigation: {
      enabled: true,
    },
  },
  1100: {
    slidesPerView: 3,
    spaceBetween: 40,
    pagination: {
      enabled: true,
    },
    navigation: {
      enabled: true,
    },
  },
};

export const ProductsSection = ({ blockTitle, title, subTitle, products, button }) => {
  const productsCount = useMemo(() => products.length, [products]);
  const availableProducts = useMemo(() => productsCount > 0, [products]);
  const sortedProducts = useMemo(() => products.sort((a, b) => a.productPrice - b.productPrice), [products]);
  const { getColor } = useColor({ size: productColors.length, colors: productColors });
  const { isLoggedIn } = useJwtAuth();
  const renderProductSlider = useMemo(
    () => (
      <Slider
        breakpoints={breakPoints}
        count={productsCount}
        navigation={products.length > 3}
        alwaysIndicator
        infinite={false}
        bottomSpaceClass={styles.products__slider__bottom__space}
      >
        {sortedProducts.map((product, index) => (
          <SwiperSlide key={product.productName}>
            <ProductItem product={product} color={getColor(index)} />
          </SwiperSlide>
        ))}
      </Slider>
    ),
    [products]
  );
  if (!availableProducts) return <></>;

  return (
    <Section title={blockTitle}>
      <Container className={styles.products__content}>
        <Container className={styles.products__header}>
          <Heading variant={2} className={styles.products__header__title}>
            {title ? title : blockTitle}
          </Heading>
        </Container>
        <Container className={clsx(styles.products__slider, !isLoggedIn && styles.products__sliderHasDescription)}>
          {renderProductSlider}
        </Container>
        <IF condition={!isLoggedIn}>
          <Container className={styles.products__button}>
            <ButtonLinkOnlyNotLoggedIn
              id={'products-section-test-button'}
              navigateTo={button.page?.slug}
              fallback={button.buttonLink}
              title={button.buttonText}
              variant={button.buttonVariant}
              size={'medium'}
            />
          </Container>
          <p className={styles.products__decription}>{subTitle}</p>
        </IF>
      </Container>
    </Section>
  );
};

ProductsSection.propTypes = {
  ...baseSectionType,
  button: buttonType.isRequired,
  products: PropTypes.arrayOf(productType),
};
