import React from 'react';

import { useSubscribeNewsletterForm } from '~/features/subscribe-newsletter';
import { BaseForm, Button, Container, Heading, Markdown, Section } from '~/shared/components';
import { FormElement, FormGroup, FormInput } from '~/shared/components/form';
import { SeoHead } from '~/widgets/layout/seo/seo.head';
import { baseSectionType } from '~/widgets/sections/typed';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';

import * as styles from './newsletter.module.scss';

export const NewsletterSection = ({ blockTitle: title, richText, button }) => {
  const { formSchema, isLoading, mutate } = useSubscribeNewsletterForm();
  const { formatMessage } = useIntl();

  return (
    <Section title={'newsletter'} className={clsx('full-width', styles.newsletter)}>
      <Container className={styles.newsletter__content}>
        <Container className={styles.newsletter__header}>
          <Heading variant={3} className={styles.newsletter__headerTitle}>
            {title}
          </Heading>
          <Container className={styles.newsletter__controls}>
            <Container className={'subscription'}>
              <BaseForm onSubmit={mutate} resolver={formSchema} defaultValues={{}} className={styles.newsletter__form}>
                {({ register, control, formState, getValues }) => (
                  <>
                    <FormElement variant={'text'} name="email" className={styles.newsletter__inputWrapper}>
                      <FormInput
                        name="email"
                        register={register}
                        control={control}
                        formState={formState}
                        getValues={getValues}
                        className="form__input"
                        placeholder={formatMessage({ id: 'input.placeholder.email' })}
                        disabled={false}
                      />
                    </FormElement>
                    <FormGroup className="button-wrapper">
                      <Button
                        id={'subscribe-newsletter-button'}
                        classNameExtended={styles.newsletter__button}
                        type={'submit'}
                        variant={button?.buttonVariant}
                        size={'small'}
                        title={button?.buttonText}
                        disabled={isLoading}
                      />
                    </FormGroup>
                  </>
                )}
              </BaseForm>
            </Container>
          </Container>
        </Container>
        <Heading variant={3} className={styles.newsletter__headerSecondTitle}>
          {title}
        </Heading>
        {richText ? <Markdown className={styles.newsletter__description} {...richText} /> : <></>}
      </Container>
    </Section>
  );
};

NewsletterSection.propTypes = {
  baseSectionType,
};
