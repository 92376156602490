// extracted by mini-css-extract-plugin
export var button = "button-module--button--2a1b7";
export var buttonBorderBlue = "button-module--button--border-blue--cd937";
export var buttonBorderLightBlue = "button-module--button--border-light-blue--e1f70";
export var buttonFull = "button-module--button-full--af396";
export var buttonGreen = "button-module--button--green--e72c2";
export var buttonInvisible = "button-module--button--invisible--0f448";
export var buttonOrange = "button-module--button--orange--cda49";
export var buttonPagination = "button-module--button--pagination--8aef0";
export var buttonSmall = "button-module--button-small--7918c";
export var buttonTransparent = "button-module--button--transparent--d1f25";