import * as yup from 'yup';

export const validationSchemaLoader = ({ formatMessage }) =>
  yup.object().shape({
    companyName: yup
      .string()
      .max(54, formatMessage({ id: 'validate.demo-request.form.companyName.max-length' }))
      .required(formatMessage({ id: 'validate.demo-request.form.companyName.required' })),
    email: yup
      .string()
      .email(formatMessage({ id: 'validate.demo-request.form.email.invalid' }))
      .required(formatMessage({ id: 'validate.demo-request.form.email.required' })),
    message: yup.string(),
    name: yup
      .string()
      .max(54, formatMessage({ id: 'validate.demo-request.form.name.max-length' }))
      .required(formatMessage({ id: 'validate.demo-request.form.name.required' })),
    qntEmployees: yup
      .string()
      .matches(/^[0-9]+$/, formatMessage({ id: 'validate.demo-request.form.only-numeric' }))
      .min(1, formatMessage({ id: 'validate.demo-request.form.qntEmployees.min-length' }))
      .max(5, formatMessage({ id: 'validate.demo-request.form.qntEmployees.max-length' }))
      .required(formatMessage({ id: 'validate.demo-request.form.qntEmployees.required' })),
    telephoneNumber: yup
      .string()
      .matches(/^[0-9+]+$/, formatMessage({ id: 'validate.demo-request.form.only-numeric' }))
      .max(15, formatMessage({ id: 'validate.demo-request.form.telephoneNumber.max-length' }))
      .required(formatMessage({ id: 'validate.demo-request.form.telephoneNumber.required' })),
    token: yup.string().required(formatMessage({ id: 'validate.demo-request.form.recaptcha.required' })),
  });
