import { useMutation } from '@tanstack/react-query';

/**
 * @param {function} action
 * @param {function} onSuccess
 * @param {function} onError
 * @param {string} formName
 * @returns {UseMuttionResult<unknown, unknown, void, unknown>}
 */
export const useFormSubmit = ({ action, onSuccess = () => {}, onError = (error, _) => true, formName }) => {
  const { mutateAsync, isLoading, error, isSuccess } = useMutation(action, {
    mutationKey: formName,
    onMutate: ({ handleValidationErrors }) => ({ handleValidationErrors }),
    onSuccess: (data, variables, { handleValidationErrors }) => {
      if (data?.statusCode === 422) {
        handleValidationErrors(data, (errors) => onError({ errors, handleValidationErrors }));
        return;
      }
      onSuccess(data);
    },
    onError: (errors, variables, { handleValidationErrors }) => {
      onError({ errors, handleValidationErrors, variables });
    },
    retry: 0,
  });
  return { mutate: mutateAsync, isLoading, error, isSuccess };
};
