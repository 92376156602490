import * as yup from 'yup';

export const validationSchemaLoader = ({ formatMessage }) =>
  yup.object().shape({
    name: yup
      .string()
      .min(2, formatMessage({ id: 'validate.contact-us.form.name.min-length' }))
      .max(54, formatMessage({ id: 'validate.contact-us.form.name.max-length' }))
      .required(formatMessage({ id: 'validate.contact-us.form.name.required' })),
    email: yup
      .string()
      .email(formatMessage({ id: 'validate.contact-us.form.email.invalid' }))
      .required(formatMessage({ id: 'validate.contact-us.form.email.required' })),
    description: yup.string(),
    token: yup.string().required(formatMessage({ id: 'validate.contact-us.form.recaptcha.required' })),
  });
