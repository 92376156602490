import React from 'react';

import { Container, Heading, Image, ListElement, Markdown, Section } from '~/shared/components';
import { gatsbyImageType } from '~/widgets/sections/typed';
import PropTypes from 'prop-types';

import * as styles from './workinfo.module.scss';

export const WorkInfoSection = ({ blockTitle, title, image, works }) => {
  return (
    <Section title={blockTitle}>
      <Container className={styles.workinfo}>
        <Heading variant={2} className={styles.workinfo__title}>
          {title ? title : blockTitle}
        </Heading>
        <Container className={styles.workinfo__content}>
          <ul className={styles.workinfo__list}>
            {works.map((work) => (
              <ListElement key={work.text} text={work.text} />
            ))}
          </ul>
          <Container>
            <Image src={image.imageFile} alt={image.altText} className={styles.workinfo__image} />
          </Container>
        </Container>
      </Container>
    </Section>
  );
};

WorkInfoSection.propTypes = {
  title: PropTypes.string.isRequired,
  works: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ),
  image: gatsbyImageType,
};
