import React, { useEffect, useMemo } from 'react';

import { useLocation } from '@reach/router';
import { sluggy } from '~/shared';
import { Accordion, Container, Heading, Section } from '~/shared/components';
import PropTypes from 'prop-types';

import * as styles from './themes.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const ThemesAccordionSection = React.memo(({ blockTitle, title = '', themen }) => {
  const { hash } = useLocation();

  const handleScrollTo = (hash) => {
    const element = document.querySelector(hash);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }, 450);
  };

  useEffect(() => {
    if (hash) return handleScrollTo(hash);
  }, []);

  useEffect(() => {
    if (!hash) {
      const firstTheme = themen.find(Boolean);
      if (!firstTheme) return;
      handleScrollTo(`#${sluggy(firstTheme?.theme)}`);
    }
  }, [hash]);

  const renderedThemes = useMemo(() => {
    let items = themen.map(({ theme, explanation }) => ({
      question: theme,
      answer: explanation,
      isActive: hash.includes(sluggy(theme)),
    }));
    const hasAnyActive = items.map(({ isActive }) => isActive).filter(Boolean).length > 0;
    if (!hasAnyActive) {
      items = items.map((item, index) => {
        if (index === 0) item.isActive = true;
        return item;
      });
    }
    return <Accordion items={items} />;
  }, [themen]);

  return (
    <Section title={blockTitle}>
      <Container className="wrapper">
        <Heading variant={2} className={styles.themes__header}>
          {title}
        </Heading>
        <Container>{renderedThemes}</Container>
      </Container>
    </Section>
  );
});

ThemesAccordionSection.propTypes = {
  themen: PropTypes.arrayOf(
    PropTypes.shape({
      theme: PropTypes.string.isRequired,
      explanation: PropTypes.string.isRequired,
    })
  ),
};
