import { useLocation } from '@reach/router';
import { subscribeToNewsletter } from '~/features/subscribe-newsletter/form/model';
import { validationSchemaLoader } from '~/features/subscribe-newsletter/form/schema';
import { useFormSubmit } from '~/shared/hooks/useFormSubmit';
import { navigate, useIntl } from 'gatsby-plugin-intl';

export const useSubscribeNewsletterForm = () => {
  const { formatMessage } = useIntl();
  const { href } = useLocation();
  const subscriptionSchema = validationSchemaLoader({ formatMessage });

  const { mutate, isLoading } = useFormSubmit({
    action: async ({ attrs }) => await subscribeToNewsletter({ ...attrs, ...{ page: href } }),
    onSuccess: () => {
      navigate('/newsletter-success');
    },
    onError: ({ errors, handleValidationErrors }) => {
      handleValidationErrors(errors, (errors) => {
        if (!errors?.response) return formatMessage({ id: 'form.generic.error' });
        return true;
      });
    },
    formName: 'subscription-up',
  });

  return {
    formSchema: subscriptionSchema,
    mutate,
    isLoading,
  };
};
