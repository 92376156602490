import React from 'react';

import { Container, Heading, Markdown, Section } from '~/shared/components';
import clsx from 'clsx';

import * as styles from './suicide-banner.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const SuicideBannerSection = React.memo(({ blockTitle, bannerTitle, bannerText }) => (
  <Section title={blockTitle} className={clsx('full-width', styles.suicideBanner)}>
    <Container className={styles.suicideBanner__content}>
      <Heading variant={3}>{bannerTitle}</Heading>
      <p>
        <Markdown {...bannerText} />
      </p>
      <span className={styles.suicideBanner__blobLeft}></span>
      <span className={styles.suicideBanner__blobRight}></span>
    </Container>
  </Section>
));
