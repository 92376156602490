/**
 * @typedef {Object} Settings
 * @property {string} API_ENDPOINT
 * @property {string} PINGA_APP_MAIN_URL
 * @property {string} SITE_DOMAIN
 * @property {string} MAIN_SITE_REWRITE_PATH
 * @property {string} CAPTCHA_SITE
 * @property {string} CAPTCHA_SECRET
 */
export const applicationSettings = {
  API_ENDPOINT: process.env.API_URL,
  PINGA_APP_MAIN_URL: process.env.PINGA_APP_MAIN_URL,
  SITE_DOMAIN: process.env.SITE_DOMAIN,
  MAIN_SITE_REWRITE_PATH: process.env.MAIN_SITE_REWRITE_PATH,
  CAPTCHA_SITE: process.env.CAPTCHA_SITE,
  CAPTCHA_SECRET: process.env.CAPTCHA_SECRET,
};
