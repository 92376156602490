import { useContext } from 'react';

import { SettingsContext } from '~/shared/context/settings.context';

export const useSiteNavigation = () => {
  const { navigation } = useContext(SettingsContext);
  return navigation;
};
export const useSiteSettings = () => {
  const { settings } = useContext(SettingsContext);
  return settings;
};
