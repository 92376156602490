import { useCallback } from 'react';

import { useBroadcastChannel } from '~/shared';

/**
 *
 * @type {{LOGOUT: string, LOGIN: string}}
 */
const broadcastEvents = {
  LOGIN: Symbol.for('user_login').description,
  LOGOUT: Symbol.for('user_logout').description,
};

/**
 * HandleBroadcastEvents function handles the broadcast events for a given channel.
 *
 * @param {Object} channel - the broadcast channel
 * @param {Function} loginAction - action to be performed on login event
 * @param {Function} logoutAction - action to be performed on logout event
 * @return {null} null
 */
export const HandleBroadcastEvents = ({ channel, loginAction, logoutAction }) => {
  const handleBroadcast = useCallback(
    ({ data }) => {
      if (data.type === broadcastEvents.LOGIN) {
        console.debug(`[${broadcastEvents.LOGIN}]`, data);
        loginAction();
      }
      if (data.type === broadcastEvents.LOGOUT) {
        console.debug(`[${broadcastEvents.LOGOUT}]`, data);
        setTimeout(() => logoutAction(), 1500);
      }
    },
    [channel]
  );
  useBroadcastChannel(channel, handleBroadcast, () => {});
  return null;
};

HandleBroadcastEvents.defautProps = {
  channel: '',
  loginAction: () => {},
  logoutAction: () => {},
};
