import React from 'react';

import * as styles from './title.module.scss';
/**
 * @param {(1|2|3|4|5)} variant
 * @param {string} className
 * @param {React.ReactNode} children
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
export const Heading = ({ variant, className, children, title = '', ...rest }) => {
  const Tag = `h${variant}`;
  return (
    <Tag className={className} title={title} {...rest}>
      {children}
    </Tag>
  );
};

export const SectionTitle = ({ variant, ...props }) => {
  return (
    <span className={styles.title}>
      <Heading variant={variant} {...props} />
    </span>
  );
};
