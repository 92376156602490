import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';

import Logo from '~/assets/images/logo/logo.svg';
import { useJwtAuth } from '~/features/auth';
import { useIsBusinessPage, useSiteNavigation, useSiteSettings } from '~/shared';
import { applicationSettings } from '~/shared/application.settings';
import {
  ButtonLink,
  Container,
  IF,
  Image,
  MenuNavigation,
  MenuNavigationLink,
  ProfileButton,
  Section,
} from '~/shared/components';
import { useClickOutside } from '~/shared/hooks/useClickOutside';
import { useMenuItemsPerPage } from '~/shared/hooks/useMenuItems';
import { useMountTransition } from '~/shared/hooks/useMountTransition';
import { LanguageSwitcher } from '~/widgets/header/header.language-switcher';
import clsx from 'clsx';
import { Link, navigate, useIntl } from 'gatsby-plugin-intl';
import * as PropTypes from 'prop-types';

import * as styles from './header.module.scss';

const MainImage = React.memo(({ siteLogo }) => {
  let LogoImage = <img src={Logo} alt={'pinga!'} className={styles.header__logo} />;
  if (siteLogo && siteLogo?.picture.localFile.childImageSharp) {
    LogoImage = <Image src={siteLogo.picture.localFile} alt={siteLogo.altText} className={styles.header__logo} />;
  }
  return <Link to={'/'}>{LogoImage}</Link>;
});

/**
 * Renders a container for header buttons with the provided children.
 *
 * @param {object} children - The children components to be rendered inside the container.
 * @return {JSX.Element} The container component with the provided children.
 */
const HeaderButtons = ({ children }) => <Container className={styles.header__buttons}>{children}</Container>;

HeaderButtons.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export const Header = React.memo(({ pageContext }) => {
  const { slug } = pageContext;
  const key = useId();
  const innerRef = useRef(null);
  const isBusinessPage = useIsBusinessPage({ pageContext });
  const { formatMessage } = useIntl();
  const { main, menusPerPage } = useSiteNavigation();
  const settings = useSiteSettings();
  const menuItems = useMenuItemsPerPage({ pagesMenu: menusPerPage, fallback: main, pageSlug: slug });
  const [menuOpen, setMenuOpen] = useState(false);
  const { setAction } = useClickOutside({ elementRef: innerRef });
  const hasTransitionedIn = useMountTransition(menuOpen, 100);
  const { isLoggedIn } = useJwtAuth();

  useEffect(() => {
    if (hasTransitionedIn || menuOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [menuOpen, hasTransitionedIn]);

  useEffect(() => {
    setAction(() => closeMenu);
  }, [innerRef?.current]);

  const expandMenu = useCallback(() => setMenuOpen((prev) => !prev), [setMenuOpen]);
  const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen]);

  const renderMenuItems = useCallback(
    (type) => (
      <MenuNavigation type={type}>
        {menuItems?.map((mainMenuItem) => (
          <MenuNavigationLink key={mainMenuItem.title} {...mainMenuItem} handleBubbling={closeMenu} />
        ))}
      </MenuNavigation>
    ),
    [expandMenu, menuItems]
  );

  const desktopMenuItems = useMemo(() => renderMenuItems('desktop'), [menuItems]);
  const mobileMenuItems = useMemo(() => renderMenuItems('mobile'), [menuItems]);

  const getNavigationLink = (path) => `${applicationSettings.MAIN_SITE_REWRITE_PATH}/${path}`;
  const navigationLink = getNavigationLink(isBusinessPage ? 'business/registration' : 'login');
  const renderAuthButton = ({ variant = 'green', size = 'small', navigateTo = navigationLink }) => (
    <ButtonLink
      id={`header-login-button-${variant}`}
      classNameExtended={styles.header__button}
      variant={variant}
      size={size}
      title={formatMessage({ id: 'header.test.label' })}
      navigateTo={navigateTo}
    />
  );
  return (
    <Section title={'header'} className={clsx(styles.header, 'sticky')} key={key}>
      <Container className="wrapper-big" ref={innerRef}>
        <Container className={styles.header__content}>
          <MainImage siteLogo={settings.siteLogo} />
          {desktopMenuItems}
          <HeaderButtons>
            <LanguageSwitcher />
            <IF condition={!isLoggedIn}>{renderAuthButton({})}</IF>
            <IF condition={isLoggedIn}>{<ProfileButton onClick={() => navigate(navigationLink)} />}</IF>
            <Container onClick={expandMenu} className={styles.header__burgerButton} />
          </HeaderButtons>
        </Container>
        {hasTransitionedIn || menuOpen ? (
          <Container
            className={clsx(
              styles.header__mobileMenu,
              hasTransitionedIn && styles.header__mobileActive,
              !menuOpen && styles.header__mobileActiveInvisible
            )}
          >
            {mobileMenuItems}
            <IF condition={!isLoggedIn}>{renderAuthButton({ variant: 'orange', size: 'large' })}</IF>
          </Container>
        ) : (
          <></>
        )}
      </Container>
    </Section>
  );
});
