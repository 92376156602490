// extracted by mini-css-extract-plugin
export var form = "form-module--form--10a0a";
export var form__blob = "form-module--form__blob--d9c72";
export var form__buttonWrapper = "form-module--form__button-wrapper--2a924";
export var form__buttonWrapperRecaptcha = "form-module--form__button-wrapper--recaptcha--09063";
export var form__content = "form-module--form__content--7c561";
export var form__content_left = "form-module--form__content_left--ae7f1";
export var form__content_right = "form-module--form__content_right--95827";
export var form__error = "form-module--form__error--f2abc";
export var form__input = "form-module--form__input--83bc4";
export var form__inputWrapper = "form-module--form__inputWrapper--b0a5b";
export var form__success = "form-module--form__success--d7c06";
export var form__textarea = "form-module--form__textarea--faa0b";
export var form__title = "form-module--form__title--eee91";