import React from 'react';

import { Button, Container, Heading } from '~/shared/components';
import * as styles from '~/widgets/sections/common.sections/contact-us-form/form.module.scss';

export const SuccessContainer = React.memo(({ successTitle, successDescription, successButton, onClick }) => {
  return (
    <Container className={styles.form__success}>
      <Heading variant={3}>{successTitle}</Heading>
      <p>{successDescription}</p>
      <Button
        onClick={onClick}
        title={successButton?.buttonText}
        variant={successButton?.buttonVariant}
        size={'small'}
      />
    </Container>
  );
});
