import React from 'react';

import './slider.css';
import { Container, Heading, Section, SlideContainer, Slider } from '~/shared/components';
import PropTypes from 'prop-types';
import { SwiperSlide } from 'swiper/react';

import * as styles from './review.module.scss';

const ReviewItem = React.memo(({ author, review }) => (
  <SlideContainer className={styles.slide__content}>
    <Heading variant={5} className={styles.slide__title}>
      {author}
    </Heading>
    <p className={styles.slide__text}>{review}</p>
  </SlideContainer>
));

const breakPoints = {
  0: {
    slidesPerView: 1,
    pagination: {
      enabled: true,
    },
    navigation: {
      enabled: false,
      hidden: true,
    },
  },
  800: {
    slidesPerView: 2,
    pagination: {
      enabled: true,
    },
    navigation: {
      enabled: true,
    },
  },
  1300: {
    slidesPerView: 3,
    pagination: {
      enabled: true,
    },
    navigation: {
      enabled: true,
    },
  },
};
const ReviewSectionComponent = ({ blockTitle, title, items }) => {
  return (
    <Section title={blockTitle} className={'full-width'} data-slider="reviews">
      <Container className={styles.reviews__content}>
        <Heading variant={2} className={styles.reviews__contentTitle}>
          {title ? title : blockTitle}
        </Heading>
        <Container className={styles.reviews__slider}>
          <Slider
            breakpoints={breakPoints}
            navigation={false}
            outSideBtn={true}
            nextArrowClass={styles.reviews__slider__buttons}
            prevArrowClass={styles.reviews__slider__buttons}
          >
            {items.map((item) => (
              <SwiperSlide key={item.author} className={styles.reviews__slide}>
                <ReviewItem {...item} />
              </SwiperSlide>
            ))}
          </Slider>
        </Container>
      </Container>
    </Section>
  );
};

ReviewSectionComponent.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      review: PropTypes.string.isRequired,
    })
  ),
};

export const ReviewSection = React.memo(ReviewSectionComponent);
