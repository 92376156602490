import React from 'react';

import check from '~/assets/images/icons/psychologist/check.svg';
import { Container, Heading, Section } from '~/shared/components';

import * as styles from './psychologist.info.module.scss';

const PsychologistInfoSectionListItem = ({ className, children }) => <li className={className}>{children}</li>;

export const PsychologistInfoSection = ({ blockTitle, items }) => {
  return (
    <Section title={blockTitle} className={styles.psychologistInfo}>
      <Container className={styles.psychologistInfo__content}>
        <Container className={styles.psychologistInfo__content__title}>
          <Heading variant={2} className={styles.psychologistInfo__content__titleHeading}>
            {blockTitle}
          </Heading>
        </Container>
        <Container>
          <ul className={styles.psychologistInfo__content__list}>
            {items.map(({ text }) => (
              <PsychologistInfoSectionListItem key={text}>
                <>
                  <img src={check} alt={text} />
                  <span>{text}</span>
                </>
              </PsychologistInfoSectionListItem>
            ))}
          </ul>
        </Container>
      </Container>
    </Section>
  );
};
