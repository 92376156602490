import React from 'react';

import { useJwtAuth } from '~/features/auth';
import { ButtonLink, IF } from '~/shared/components';

/**
 * Function to render a ButtonLink only if the user is not logged in.
 *
 * @param {object} props - the properties for the component
 * @return {JSX.Element} the rendered ButtonLink or null
 */
export const ButtonLinkOnlyNotLoggedIn = (props) => {
  const { isLoggedIn } = useJwtAuth();
  return (
    <IF condition={!isLoggedIn}>
      <ButtonLink {...props} />
    </IF>
  );
};
