import React, { useCallback, useMemo } from 'react';

import account from '~/assets/images/icons/family/account-plus.svg';
import chat from '~/assets/images/icons/family/chatbox.svg';
import question from '~/assets/images/icons/family/question.svg';
import { Container, Heading, Section } from '~/shared/components';
import PropTypes from 'prop-types';

import * as styles from './promo.module.scss';

const icons = { account, chat, question };
/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const PromoAdvantageSection = React.memo(({ blockTitle, title, items }) => {
  const getIcon = useCallback((iconVariant) => icons[iconVariant], []);
  const renderPromoItems = useMemo(
    () =>
      items.map(({ advantage, icon }, index) => (
        <Container key={advantage}>
          <img src={getIcon(icon)} alt={'chat box'} />
          <p>{advantage}</p>
        </Container>
      )),
    [items]
  );
  return (
    <Section title={blockTitle}>
      <Container className={styles.promoGreen__content}>
        <Heading variant={2}>{title ? title : blockTitle}</Heading>
        <Container className={styles.promoGreen__list}>{renderPromoItems}</Container>
      </Container>
    </Section>
  );
});

PromoAdvantageSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      advantage: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
};
