import React from 'react';

import { inputPlaceholders, inputSizes, inputVariants } from '~/shared/components/ui/elements/input/typed';
import clsx from 'clsx';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';

import * as styles from './container.module.scss';

/**
 * @type React.ForwardRefRenderFunction<HTMLButtonElement, ButtonPropTypes>
 */

export const Input = React.forwardRef(
  (
    { variant, placeholder, className, type = 'text', onClick = () => {}, disabled, size = 'regular', ...rest },
    ref
  ) => (
    <input
      ref={ref}
      type={type}
      {...rest}
      className={
        className ? className : clsx('input', styles[camelCase(`input-${variant}`)], styles[camelCase(`input-${size}`)])
      }
      onClick={onClick}
      disabled={disabled}
      placeholder={inputPlaceholders[inputVariants.indexOf(type)]}
    />
  )
);

const InputPropTypes = {
  variant: PropTypes.oneOf(inputVariants),
  size: PropTypes.oneOf(inputSizes),
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.propTypes = InputPropTypes;
