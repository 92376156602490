// ---------------------------------- Common sections ------------------------------------------------------------------
export const STRAPI__COMPONENT_PAGE_BLOCKS_WELCOME_SECTION = 'ComponentPageBlocksWelcomSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_SUPPORT_SECTION = 'ComponentPageBlocksSupportSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_ADVANTAGES_SECTION = 'ComponentPageBlocksAdvantagesSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_MARKDOWN_SECTION = 'ComponentPageBlocksMarkdownSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_FAQ_SECTION = 'ComponentPageBlocksFaqSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_REVIEW_SECTION = 'ComponentPageBlocksReviewSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_PSYCHOLOGIST_SECTION = 'ComponentPageBlocksPsychologistSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_ABOUT_SECTION = 'ComponentPageBlocksAboutSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_SPONSORS_SECTION = 'ComponentPageBlocksSponsorsSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_THEMES_SECTION = 'ComponentPageBlocksThemesSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_THEMES_SECTION_ACCORDION = 'ComponentPageBlocksThemesSectionAccordion';
export const STRAPI__COMPONENT_PAGE_BLOCKS_BLOG_SECTION = 'ComponentPageBlocksBlogSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_PRODUCTS_SECTION = 'ComponentPageBlocksProductsSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_NEWSLETTER_SECTION = 'ComponentPageBlocksNewsletterSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_FULL_WIDTH_BANNER_SECTION = 'ComponentPageBlocksFullWidthBannerSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_CONTACT_US_SECTION = 'ComponentPageBlocksContactUsSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_SUICIDE_BANNER_SECTION = 'ComponentPageBlocksSuicideBannerSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_PINGA_STATISTICS_SECTION = 'ComponentPageBlocksStatisticsSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_PROMO_ADVANTAGES_SECTION = 'ComponentPageBlocksPromoAdvantages';
export const STRAPI__COMPONENT_PAGE_BLOCKS_TEST_BUTTON_SECTION = 'ComponentPageBlocksTestStartedButtonSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_TEAM_SECTION = 'ComponentPageBlocksTeamSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_ABOUT_US_HEADER_SECTION = 'ComponentPageBlocksAboutUsHeaderSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_OUR_MISSION_SECTION = 'ComponentPageBlocksOurMissionSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_WORK_INFO_SECTION = 'ComponentPageBlocksWorkInfoSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_PROMO_SECTION = 'ComponentPageBlocksPromoSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_CONTACT_US_FORM_SECTION = 'ComponentPageBlocksContactUsFormSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_PARTNERS_SECTION = 'ComponentPageBlocksPartnersSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_LIST_OF_POINTS_SECTION = 'ComponentPageBlocksListOfPointsSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_SHARE_SECTION = 'ComponentPageBlocksShareSection';

const commonSections = {
  'page-blocks.welcom-section': STRAPI__COMPONENT_PAGE_BLOCKS_WELCOME_SECTION,
  'page-blocks.support-section': STRAPI__COMPONENT_PAGE_BLOCKS_SUPPORT_SECTION,
  'page-blocks.advantages-section': STRAPI__COMPONENT_PAGE_BLOCKS_ADVANTAGES_SECTION,
  'page-blocks.markdown-section': STRAPI__COMPONENT_PAGE_BLOCKS_MARKDOWN_SECTION,
  'page-blocks.faq-section': STRAPI__COMPONENT_PAGE_BLOCKS_FAQ_SECTION,
  'page-blocks.review-section': STRAPI__COMPONENT_PAGE_BLOCKS_REVIEW_SECTION,
  'page-blocks.psychologist-section': STRAPI__COMPONENT_PAGE_BLOCKS_PSYCHOLOGIST_SECTION,
  'page-blocks.about-section': STRAPI__COMPONENT_PAGE_BLOCKS_ABOUT_SECTION,
  'page-blocks.sponsors-section': STRAPI__COMPONENT_PAGE_BLOCKS_SPONSORS_SECTION,
  'page-blocks.themes-section': STRAPI__COMPONENT_PAGE_BLOCKS_THEMES_SECTION,
  'page-blocks.themes-section-accordion': STRAPI__COMPONENT_PAGE_BLOCKS_THEMES_SECTION_ACCORDION,
  'page-blocks.blog-section': STRAPI__COMPONENT_PAGE_BLOCKS_BLOG_SECTION,
  'page-blocks.products-section': STRAPI__COMPONENT_PAGE_BLOCKS_PRODUCTS_SECTION,
  'page-blocks.newsletter-section': STRAPI__COMPONENT_PAGE_BLOCKS_NEWSLETTER_SECTION,
  'page-blocks.full-width-banner-section': STRAPI__COMPONENT_PAGE_BLOCKS_FULL_WIDTH_BANNER_SECTION,
  'page-blocks.contact-us-section': STRAPI__COMPONENT_PAGE_BLOCKS_CONTACT_US_SECTION,
  'page-blocks.suicide-banner-section': STRAPI__COMPONENT_PAGE_BLOCKS_SUICIDE_BANNER_SECTION,
  'page-blocks.statistics-section': STRAPI__COMPONENT_PAGE_BLOCKS_PINGA_STATISTICS_SECTION,
  'page-blocks.promo-advantages': STRAPI__COMPONENT_PAGE_BLOCKS_PROMO_ADVANTAGES_SECTION,
  'page-blocks.test-started-button-section': STRAPI__COMPONENT_PAGE_BLOCKS_TEST_BUTTON_SECTION,
  'page-blocks.team-section': STRAPI__COMPONENT_PAGE_BLOCKS_TEAM_SECTION,
  'page-blocks.our-mission-section': STRAPI__COMPONENT_PAGE_BLOCKS_OUR_MISSION_SECTION,
  'page-blocks.work-info-section': STRAPI__COMPONENT_PAGE_BLOCKS_WORK_INFO_SECTION,
  'page-blocks.about-us-header-section': STRAPI__COMPONENT_PAGE_BLOCKS_ABOUT_US_HEADER_SECTION,
  'page-blocks.promo-section': STRAPI__COMPONENT_PAGE_BLOCKS_PROMO_SECTION,
  'page-blocks.contact-us-form-section': STRAPI__COMPONENT_PAGE_BLOCKS_CONTACT_US_FORM_SECTION,
  'page-blocks.partners-section': STRAPI__COMPONENT_PAGE_BLOCKS_PARTNERS_SECTION,
  'page-blocks.list-of-points': STRAPI__COMPONENT_PAGE_BLOCKS_LIST_OF_POINTS_SECTION,
  'page-blocks.share-section': STRAPI__COMPONENT_PAGE_BLOCKS_SHARE_SECTION,
};
// ---------------------------------- END Common sections --------------------------------------------------------------

// ---------------------------------- Business sections ----------------------------------------------------------------

export const STRAPI__COMPONENT_PAGE_BLOCKS_ADVANTAGES_BUSINESS_SECTION = 'ComponentPageBlocksAdvantagesBusinessSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_PROMO_BUSINESS_SECTION = 'ComponentPageBlocksPromoBusinessSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_BUSINESS_PSYCHOLOGIST_SECTION =
  'ComponentPageBlocksBusinessPsychologistSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_BUSINESS_BENEFITS_SECTION = 'ComponentPageBlocksBusinessBenefitsSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_PARTNERS_BUSINESS_SECTION = 'ComponentPageBlocksPartnersBusinessSection';
export const STRAPI__COMPONENT_PAGE_BLOCKS_DEMO_FORM_BUSINESS_SECTION = 'ComponentPageBlocksDemoFormBusinessSection';

const businessSections = {
  'page-blocks.advantages-business-section': STRAPI__COMPONENT_PAGE_BLOCKS_ADVANTAGES_BUSINESS_SECTION,
  'page-blocks.promo-business-section': STRAPI__COMPONENT_PAGE_BLOCKS_PROMO_BUSINESS_SECTION,
  'page-blocks.business-psychologist-section': STRAPI__COMPONENT_PAGE_BLOCKS_BUSINESS_PSYCHOLOGIST_SECTION,
  'page-blocks.business-benefits-section': STRAPI__COMPONENT_PAGE_BLOCKS_BUSINESS_BENEFITS_SECTION,
  'page-blocks.partners-business-section': STRAPI__COMPONENT_PAGE_BLOCKS_PARTNERS_BUSINESS_SECTION,
  'page-blocks.demo-form-business-section': STRAPI__COMPONENT_PAGE_BLOCKS_DEMO_FORM_BUSINESS_SECTION,
};
// ---------------------------------- END Business sections ------------------------------------------------------------

// ---------------------------------- Export ALL sections --------------------------------------------------------------
export const cmsSections = {
  ...commonSections,
  ...businessSections,
};
