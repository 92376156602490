import React from 'react';

import { Container, Heading, Image, Section } from '~/shared/components';

import * as styles from './sponsors.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const SponsorsSection = ({ blockTitle, title, image }) => (
  <Section title={blockTitle}>
    <Container className={styles.sponsors__content}>
      <Heading variant={4}>{title ? title : blockTitle}</Heading>
      <Image src={image.imageFile} alt={image.altText} />
    </Container>
  </Section>
);
