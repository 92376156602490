import {useEffect, useRef} from 'react';

class WorkerInterval {
    worker = null;

    /**
     * Constructor for creating a new instance of the class.
     *
     * @param {function} callback - The function to be called at each interval.
     * @param {number} interval - The time interval for the setInterval function.
     */
    constructor(callback = () => {}, interval) {
        const blob = new Blob([`setInterval(() => postMessage(0), ${interval});`]);
        const workerScript = URL.createObjectURL(blob);
        this.worker = new Worker(workerScript);
        this.worker.onmessage = callback;
    }
    /**
     * Stop the worker.
     *
     */
    stop() {
        this.worker.terminate();
    }
}
/**
 * Custom hook that sets up an interval for invoking the provided callback function.
 *
 * @param {function} callback - The function to be invoked at each interval
 * @param {number} timeout - The time interval in milliseconds
 */
export const useInterval = (callback= () => {}, timeout) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        if (timeout == null) {
            return () => {};
        }
        const tick = () => savedCallback.current();
        const interval = new WorkerInterval(tick, timeout);
        return () => interval.stop();
    }, [timeout, savedCallback]);
};
