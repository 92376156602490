import React from 'react';

import { Accordion, Container, Heading, Section } from '~/shared/components';
import PropTypes from 'prop-types';

import * as styles from './faq.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const FaqSection = React.memo(({ blockTitle, title, items }) => (
  <Section title={blockTitle}>
    <Container className={styles.faq__content}>
      <Heading variant={2} className={styles.faq__title}>
        {title ? title : blockTitle}
      </Heading>
      {items.length > 0 ? <Accordion items={items} /> : <></>}
    </Container>
  </Section>
));

FaqSection.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })
  ),
};
