import { useCallback } from 'react';
import { useLoading } from 'react-use-loading';

import { useJWTStorage } from '~/features/auth/by-cookie/jwt/hooks/useJWTStorage';
import { getJwtRefresh, refreshTokenAction } from '~/features/auth/by-cookie/jwt/model';
import { useStore } from '~/shared';
import { provideJWTHeader } from '~/shared/api';
import { AuthContext } from '~/shared/context/auth.context';
import isEqual from 'lodash.isequal';

/**
 * Returns the JWT authentication state.
 *
 * @returns {Object} The authentication state, containing the `isLoggedIn` property and the `syncJwt` function.
 */
export const useJwtAuth = () => {
  const [token, setStore] = useStore(AuthContext, (store) => store['jwt']);
  const setToken = (value) => setStore({ jwt: value });
  return { token, setToken, isLoggedIn: !!token };
};

/**
 * The useAuth function manages authentication tokens and provides a way to synchronize the JWT token.
 *
 * @return {Object} The object containing isLoggedIn and syncJwt properties.
 */
export const useAuth = () => {
  const { token, setToken, isLoggedIn } = useJwtAuth();
  const { getJwt, storeTokens, deleteJwt } = useJWTStorage();
  const [{ isLoading }, { start, stop }] = useLoading(true);

  const syncJwt = useCallback(() => {
    if (typeof window === `undefined`) return;
    const jwt = getJwt();
    if (typeof jwt === 'undefined') {
      setToken(null);
      return;
    }
    setToken(jwt);
    provideJWTHeader(jwt);
    refreshAvailableToken().then((accessToken) => {
      if (isEqual(token, accessToken)) {
        provideJWTHeader(accessToken);
      }
    });
  }, []);

  /**
   * A function that retrieves the session JWT.
   *
   * @return {type} description of return value
   */
  const getSessionJwt = () => {
    try {
      start();
      syncJwt();
    } finally {
      stop();
    }
  };

  /**
   * Clears the token by deleting the JWT and setting the token to null.
   */
  const clearToken = () => {
    deleteJwt();
    setToken(null);
  };

  /**
   * Refreshes the available token by getting the refresh token,
   * executing the refresh token action, and then storing the new tokens.
   *
   * @return {Promise<void>}
   */
  const refreshAvailableToken = async () => {
    const refreshToken = getJwtRefresh();
    if (!refreshToken) return;
    const data = await refreshTokenAction(refreshToken);
    if (!data) {
      clearToken();
      return;
    }
    const {
      data: { bearerToken },
    } = data;
    await storeTokens({ accessToken: bearerToken, refreshToken: refreshToken });
    return bearerToken;
  };

  return { isLoggedIn, syncJwt: getSessionJwt, isPending: isLoading };
};
