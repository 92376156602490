// extracted by mini-css-extract-plugin
export var header = "header-module--header--42143";
export var header__burgerButton = "header-module--header__burger-button--5dd75";
export var header__button = "header-module--header__button--236e8";
export var header__buttons = "header-module--header__buttons--cba2a";
export var header__content = "header-module--header__content--295d3";
export var header__desktopMenu = "header-module--header__desktop-menu--1d83d";
export var header__list = "header-module--header__list--7d403";
export var header__logo = "header-module--header__logo--39245";
export var header__mobileActive = "header-module--header__mobile-active--55fda";
export var header__mobileActiveInvisible = "header-module--header__mobile-active--invisible--96f78";
export var header__mobileButton = "header-module--header__mobile-button--8cc89";
export var header__mobileList = "header-module--header__mobile-list--905eb";
export var header__mobileMenu = "header-module--header__mobile-menu--49e1b";