import { useEffect, useRef } from 'react';

/**
 * Custom hook for using the BroadcastChannel API to create a communication channel and listen for messages and errors.
 *
 * @param {string} channel - The name of the channel to create or connect to.
 * @param {Function} onMessage - The callback function to handle incoming messages.
 * @param {Function} onError - The callback function to handle errors on the channel.
 */
export const useBroadcastChannel = (channel, onMessage, onError) => {
  const channelRef = useRef(
    typeof window !== 'undefined' && 'BroadcastChannel' in window ? new BroadcastChannel(channel) : null
  );
  useChannelEventListener(channelRef, 'message', onMessage);
  useChannelEventListener(channelRef, 'messageerror', onError);
};

/**
 * Custom hook to add an event listener to a channel reference and remove it on cleanup.
 *
 * @param {object} channelRef - reference to the channel
 * @param {string} event - event to listen for
 * @param {function} [handler=(e) => {}] - event handler
 * @return {void}
 */
const useChannelEventListener = (channelRef, event, handler = (e) => {}) => {
  useEffect(() => {
    const channel = channelRef.current;
    if (!channel) return () => {};
    channel.addEventListener(event, handler);
    return () => channel.removeEventListener(event, handler);
  }, [channelRef, event, handler]);
};
