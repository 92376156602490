import { buttonVariants } from '~/shared/components/ui/elements/button/typed';
import PropTypes from 'prop-types';

export const baseSectionType = PropTypes.shape({
  title: PropTypes.string,
  blockTitle: PropTypes.string,
  subTitle: PropTypes.string,
});
export const buttonType = PropTypes.shape({
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonVariant: PropTypes.oneOf(buttonVariants),
  page: PropTypes.shape({
    slug: PropTypes.string,
  }),
});

export const gatsbyImageType = PropTypes.shape({
  imageFile: PropTypes.object,
  altText: PropTypes.string,
});
