import React from 'react';

import { Container, Heading, Image } from '~/shared/components';
import { gatsbyImageType } from '~/widgets/sections/typed';
import PropTypes from 'prop-types';

import * as styles from './scientific.module.scss';

export const Scientific = ({ image, name, description, className }) => {
  return (
    <Container className={styles.scientific}>
      <Image className={styles.scientific__img} src={image.imageFile} alt={name} />
      <Container>
        <Heading variant={4} className={styles.scientific__name}>
          {name}
        </Heading>
        <p className={styles.scientific__description}>{description}</p>
      </Container>
    </Container>
  );
};

const ScientificPropTypes = {
  image: gatsbyImageType,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

Scientific.propTypes = ScientificPropTypes;
