import React from 'react';
import { useFormContext } from 'react-hook-form';

import '../animation.scss';
import { Container } from '~/shared/components';
import { ErrorMessage } from '~/shared/components/form/form-error';
import clsx from 'clsx';
import { camelCase } from 'lodash';
import get from 'lodash.get';

import * as styles from './container.module.scss';
const FormGroup = ({ children, className }) => <Container className={className}>{children}</Container>;

const useFormElementError = ({ name }) => {
  const methods = useFormContext();
  return get(methods.formState.errors, name);
};

/**
 *
 * @param {('text'|'email'|'password')} variant
 * @param {string} labelFor
 * @param {string} label
 * @param {React.ReactNode} children
 * @param {string} className
 * @param {React.ReactNode} helpText
 * @param {string} name
 * @returns {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line react/display-name
const FormElement = React.memo(
  ({
    variant,
    labelFor,
    label = null,
    labelBefore = null,
    children,
    className,
    helpText: HelpBlock = null,
    name = 'email',
  }) => {
    const error = useFormElementError({ name });
    return (
      <>
        <Container
          className={clsx(
            className,
            !!error && styles.form__error,
            styles[camelCase(`input-${variant}`)],
            HelpBlock ? 'enable-hint' : ''
          )}
        >
          {label ? (
            <label className="form__label" htmlFor={labelFor}>
              <span>{label}</span>
              <span>{labelBefore}</span>
            </label>
          ) : (
            <></>
          )}

          {children}
          {HelpBlock ? <span className={'form__input--hint'}>{HelpBlock}</span> : <></>}
          <ErrorMessage errors={error} />
        </Container>
      </>
    );
  }
);

FormElement.defaultProps = {
  className: 'form__input-wrapper',
  variant: 'text',
};

export { FormElement, FormGroup };
