import React from 'react';

import { Container, Image } from '~/shared/components';
import { gatsbyImageType } from '~/widgets/sections/typed';
import PropTypes from 'prop-types';

import * as styles from './teammate.module.scss';

export const Teammate = React.forwardRef(({ image, name, specialization, className, onChange = () => {} }, ref) => {
  return (
    <Container className={styles.teammate}>
      <Image objectFit="contain" src={image.imageFile} alt={name} />
      <Container ref={ref} className={styles.teammate__text}>
        <span className={styles.teammate__name}>{name}</span>
        <span className={styles.teammate__specialization}>{specialization}</span>
      </Container>
    </Container>
  );
});

const TeammatePropTypes = {
  image: gatsbyImageType,
  name: PropTypes.string.isRequired,
  specialization: PropTypes.string.isRequired,
};

Teammate.propTypes = TeammatePropTypes;
