import { useCallback } from 'react';

import { applicationSettings } from '~/shared/application.settings';

export const useApplicationSiteRedirect = () => {
  const buildUri = useCallback((to) => `${applicationSettings.PINGA_APP_MAIN_URL}${to}`, []);
  const navigate = useCallback((to) => {
    window.location.href = buildUri(to);
  }, []);
  return {
    navigate,
  };
};
