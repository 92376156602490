import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Container } from '~/shared/components';
import * as styles from '~/shared/components/form/form-input/container.module.scss';

const duration = 300;
const useFieldError = ({ errors, name }) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(errors?.[name]);
  }, [errors?.[name]]);

  return error;
};

export const ErrorBlock = ({ children }) => <Container className={styles.form__inputError}>{children}</Container>;

export const ErrorMessageWrap = ({ enabled, errors, name }) => {
  if (!enabled) return null;
  const error = useFieldError({ errors, name });
  return !!error ? <ErrorMessage errors={error} /> : <></>;
};
ErrorMessageWrap.defaultProps = {
  enabled: false,
};

export const ErrorMessage = React.memo(({ errors }) => {
  return (
    <>
      <TransitionGroup className={styles.transitionBlock}>
        <CSSTransition
          in={!!errors?.message}
          unmountOnExit
          key={!!errors?.message}
          timeout={duration}
          classNames="error"
        >
          <div>{!!errors?.message ? <ErrorBlock>{errors?.message}</ErrorBlock> : <></>}</div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
});
