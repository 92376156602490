import React from 'react';

import { Container, Heading, Scientific, Section, Teammate } from '~/shared/components';
import { gatsbyImageType } from '~/widgets/sections/typed';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import * as styles from './team.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const TeamSection = ({ blockTitle, title, teammates, scientific }) => (
  <Section title={blockTitle} className={clsx('breakout', styles.team)}>
    <Container>
      <Heading variant={2} className={clsx(teammates.length ? styles.team__title : styles.team__titleScientific)}>
        {title ? title : blockTitle}
      </Heading>
      <Container className={clsx(styles.team__teammates, scientific.length && styles.team__teammatesScientific)}>
        {(teammates.length ? teammates : scientific).map((teammate) =>
          teammates.length ? (
            <Teammate
              key={teammate.name}
              image={teammate.image}
              name={teammate.name}
              specialization={teammate.specialization}
            />
          ) : (
            <Scientific
              key={teammate.name}
              image={teammate.image}
              name={teammate.name}
              description={teammate.description}
            />
          )
        )}
      </Container>
    </Container>
  </Section>
);

TeamSection.propTypes = {
  title: PropTypes.string.isRequired,
  teammates: PropTypes.arrayOf(
    PropTypes.shape({
      image: gatsbyImageType,
      name: PropTypes.string.isRequired,
      specialization: PropTypes.string.isRequired,
    })
  ),
  scientific: PropTypes.arrayOf(
    PropTypes.shape({
      image: gatsbyImageType,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
};
