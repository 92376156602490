import { useEffect, useState } from 'react';

export const useClickOutside = ({ elementRef }) => {
  const [action, setAction] = useState(() => (event) => {});

  const behaviour = (event) => {
    if (!elementRef?.current?.contains(event.target)) {
      action(event);
    }
  };

  useEffect(() => {
    if (typeof window === `undefined`) return;
    window.addEventListener('mousedown', behaviour);
    return () => {
      window.removeEventListener('mousedown', behaviour);
    };
  }, [action]);

  return { setAction };
};
