import * as SECTIONS from '~/shared/api/strapi';

import { AdvantagesBusinessSection } from './advantage.section';
import { BenefitsSection } from './benefits.section';
import { DemoForm } from './demo.form';
import { PartnersSection } from './partners.section';
import { PromoCardsBusinessSection } from './promo.cards.section';
import { PsychologistInfoSection } from './psychologist.info.section';

export const businessSections = {
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_ADVANTAGES_BUSINESS_SECTION]: AdvantagesBusinessSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_PROMO_BUSINESS_SECTION]: PromoCardsBusinessSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_BUSINESS_PSYCHOLOGIST_SECTION]: PsychologistInfoSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_BUSINESS_BENEFITS_SECTION]: BenefitsSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_PARTNERS_BUSINESS_SECTION]: PartnersSection,
  [SECTIONS.STRAPI__COMPONENT_PAGE_BLOCKS_DEMO_FORM_BUSINESS_SECTION]: DemoForm,
};
