import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Container } from '~/shared/components';
import { useClickOutside } from '~/shared/hooks/useClickOutside';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import * as styles from './dropdown.module.scss';

/**
 * Renders a drop down chevron component.
 *
 * @param {object} isOpen - A boolean indicating whether the drop down is open
 * @return {JSX.Element} The drop down chevron component
 */
const DropDownChevron = ({ isOpen }) => (
  <Container className={styles.dropdown__chevronContainer}>
    <span className={clsx(styles.dropdown__chevron, isOpen && styles.dropdown__chevronOpen)}></span>
  </Container>
);

/**
 * Renders a DropDownChipBlock component with the given isOpen state and children.
 *
 * @param {boolean} isOpen - Indicates whether the dropdown is open or not
 * @param {ReactNode} children - The children components to be rendered inside the dropdown
 * @return {ReactNode} The rendered DropDownChipBlock component
 */
const DropDownChipBlock = ({ isOpen, children }) => (
  <ul className={clsx(styles.dropdown__list, isOpen && styles.dropdown__listOpen)}>{children}</ul>
);

/**
 * Renders a drop down chip with the specified title and value, and triggers the onClick event handler when clicked.
 *
 * @param {function} onClick - The event handler function to be called when the chip is clicked
 * @param {string} title - The title to be displayed on the chip
 * @param {any} value - The value associated with the chip
 * @return {JSX.Element} The rendered drop down chip element
 */
const DropDownChip = ({ onClick, title, value }) => <li onClick={() => onClick(value)}>{title}</li>;

export const Dropdown = React.forwardRef(({ options, active, openByDefault, className, onChange = () => {} }, ref) => {
  const [selected, setSelected] = useState(active);
  const [isOpen, setIsOpen] = useState(openByDefault || false);
  const innerRef = useRef();
  const { setAction } = useClickOutside({ elementRef: innerRef });

  useEffect(() => {
    setAction(() => () => setIsOpen(false));
  }, [innerRef?.current]);

  const changeOption = useCallback(
    (option) => {
      onChange(option);
      setSelected(option);
      setIsOpen(false);
    },
    [onChange, options]
  );

  const renderOptions = useMemo(
    () =>
      options.map(({ title, value }) => (
        <DropDownChip key={title} onClick={changeOption} title={title} value={value} />
      )),
    [options]
  );
  return (
    <Container ref={innerRef} className={styles.dropdown}>
      <Container ref={ref} className={styles.dropdown__chevronWrapper} onClick={() => setIsOpen((prev) => !prev)}>
        <span>{selected}</span>
        <DropDownChevron isOpen={isOpen} />
      </Container>
      <DropDownChipBlock isOpen={isOpen}>{renderOptions}</DropDownChipBlock>
    </Container>
  );
});

const DropdownPropTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  active: PropTypes.string.isRequired,
  openByDefault: PropTypes.bool,
};

Dropdown.propTypes = DropdownPropTypes;
