import React from 'react';

import { Accordion as ReactAccordion, AccordionItem as Item } from '@szhsin/react-accordion';
import { sluggy } from '~/shared';
import { Container } from '~/shared/components';
import PropTypes from 'prop-types';

import * as styles from './accordion.module.scss';

const AccordionItem = React.memo(({ header, ...rest }) => (
  <Item
    {...rest}
    id={sluggy(header)}
    header={
      <>
        <span>{header}</span>
        <Container className={styles.chevron} />
      </>
    }
    className={styles.item}
    buttonProps={{
      className: ({ isEnter }) => `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`,
    }}
    contentProps={{ className: styles.itemContent }}
    panelProps={{ className: styles.itemPanel }}
  />
));

/**
 * @typedef {Object} AccordionItem
 * @property {string} question
 * @property {string} answer
 * @property {bool} isActive
 */
/**
 * @type {React.FC<Props>}
 * @param {number} transitionDuration
 * @param {AccordionItem[]} items
 * @returns {React.JSX.Element}
 */
export const Accordion = React.memo(({ transitionDuration = 250, items = [] }) => {
  return (
    <Container
      className={styles.accordion}
      style={{
        '--transition-duration': `${transitionDuration}ms`,
      }}
    >
      <ReactAccordion transition transitionTimeout={transitionDuration}>
        {items.map((item) => (
          <AccordionItem
            itemKey={sluggy(item.question)}
            key={item.question}
            header={item.question}
            initialEntered={item.isActive}
          >
            {item.answer}
          </AccordionItem>
        ))}
      </ReactAccordion>
    </Container>
  );
});

Accordion.propTypes = {
  transitionDuration: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
    })
  ),
};
