import React, { useMemo } from 'react';

import check from '~/assets/images/icons/psychologist/check-circle.svg';
import { Container, Heading, Image, Section, SlideContainer, Slider } from '~/shared/components';
import PropTypes from 'prop-types';
import { SwiperSlide } from 'swiper/react';

import * as styles from './psychologist.module.scss';

const PsychologistSliderItem = React.memo(
  ({ psychologistImageFile: psychologist, licenseImageFile: license, psychologistName, grade, line: lines }) => (
    <SlideContainer className={styles.slide}>
      <Container className={styles.slide__left}>
        <Container className={styles.slide__imageContainer}>
          <Image src={psychologist} alt={psychologistName} className={styles.slide__image} />
          {license && (
            <Image src={license} alt={`license ${psychologistName}`} className={styles.slide__imageLicense} />
          )}
        </Container>
        <Heading variant={4} className={styles.slide__name}>
          {psychologistName}
        </Heading>
        <p className={styles.slide__degrees}>{grade}</p>
      </Container>
      <Container className={styles.slide__right}>
        {lines.map(({ lineName, description }) => (
          <>
            <p className={styles.slide__textFirstLine}>{lineName}</p>
            <p className={styles.slide__text}>{description}</p>
          </>
        ))}
      </Container>
    </SlideContainer>
  )
);

export const PsychologistSection = React.memo(({ blockTitle, title, benefits, psychologist: psychologists }) => {
  const hasPsychologists = useMemo(() => psychologists.length > 0, [psychologists]);

  if (!hasPsychologists) return <></>;
  const renderBenefits = useMemo(
    () => (
      <ul className={styles.psychologist__advantagis}>
        {benefits.map((benefit) => (
          <li className={styles.advantagis__item} key={benefit.benefit}>
            <img src={check} alt={'checked'} width={'40'} height={40} />
            <span>{benefit.benefit}</span>
          </li>
        ))}
      </ul>
    ),
    [benefits]
  );
  const renderPsychologists = useMemo(
    () => (
      <Slider
        navigation={false}
        pagination={false}
        slidesPerView={1}
        outSideBtn={true}
        prevArrowClass={styles.psychologist__slider__button__prev}
        nextArrowClass={styles.psychologist__slider__button__next}
      >
        {psychologists.map((psychologist) => (
          <SwiperSlide key={psychologist.psychologistName} className={styles.slide}>
            <PsychologistSliderItem {...psychologist} />
          </SwiperSlide>
        ))}
      </Slider>
    ),
    [psychologists]
  );
  return (
    <Section title={blockTitle} className={styles.psychologist}>
      <Container className={styles.psychologist__content}>
        <Container className={styles.psychologist__header}>
          <Heading variant={2} className={styles.psychologist__headerTitle}>
            {title ? title : blockTitle}
          </Heading>
          <Container>{renderBenefits}</Container>
        </Container>
        <Container className={styles.psychologist__slider}>{renderPsychologists}</Container>
      </Container>
    </Section>
  );
});

PsychologistSection.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      benefit: PropTypes.string.isRequired,
    })
  ),
  psychologist: PropTypes.arrayOf(
    PropTypes.shape({
      psychologistName: PropTypes.string.isRequired,
      grade: PropTypes.string.isRequired,
      line: PropTypes.arrayOf(
        PropTypes.shape({
          lineName: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};
