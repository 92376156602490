import React from 'react';

import PropTypes from 'prop-types';

import * as styles from './list.module.scss';

export const ListElement = ({ text }) => {
  return <li className={styles.list__item}>{text}</li>;
};

const ListElementPropTypes = {
  text: PropTypes.string.isRequired,
};

ListElement.propTypes = ListElementPropTypes;
