import { useCallback, useMemo, useState } from 'react';

import { sluggy } from '~/shared';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';

const useProcessMenuItems = ({ slug = null }) => {
  const processMenuForSection = ({ title, related }) => {
    return {
      title: title,
      link: slug ? `/${slug}#${sluggy(related.title)}` : `#${sluggy(related.title)}`,
      target: '_self',
    };
  };

  const processMenuForPage = ({ title, related }) => {
    return {
      title: title,
      link: `/${related.slug}`,
      target: '_self',
    };
  };

  const getMenuItems = useCallback(
    (items) =>
      items.map((menuItem) => {
        if (menuItem.type === 'WRAPPER') {
          return {
            title: menuItem.title,
            link: slug ? `/${slug}${menuItem.path}` : menuItem.path,
            target: '_self',
          };
        }
        if (menuItem.related) {
          const { _xcontentType } = menuItem.related;
          switch (_xcontentType) {
            case 'api::section.section':
              return processMenuForSection(menuItem);
            case 'api::page.page':
              return processMenuForPage(menuItem);
          }
        }
        return {
          title: menuItem.title,
          link: menuItem.externalPath,
          target: menuItem._blank,
          external: true,
        };
      }),
    [slug]
  );

  return {
    processMenuItems: getMenuItems,
  };
};

export const useMenuItems = ({ main = [] }) => {
  const items = useMemo(() => get(main.edges, '[0].node.items', []), [main]);
  const { processMenuItems } = useProcessMenuItems({});
  return processMenuItems(items);
};

export const useMenuItemsPerPage = ({ pageSlug, pagesMenu = [], fallback = [] }) => {
  const { processMenuItems } = useProcessMenuItems({ slug: pageSlug });
  const { processMenuItems: processMenuItemsWithoutSlug } = useProcessMenuItems({});
  const fallBackItems = useMemo(() => get(fallback.edges, '[0].node.items', []), [fallback, pageSlug]);
  return useMemo(() => {
    const items = pagesMenu.find(({ pages }) => pages.filter(({ slug }) => slug === pageSlug).length > 0)?.items;
    if (!isEmpty(items)) return processMenuItems(items);
    return processMenuItemsWithoutSlug(fallBackItems);
  }, [pagesMenu, pageSlug, fallback]);
};

export const useFooterMenuItems = ({ themen, pages }) => {
  const themenItems = useMemo(() => get(themen?.edges, '[0].node.items', []), [themen]);
  const pagesItems = useMemo(() => get(pages?.edges, '[0].node.items', []), [pages]);

  const { processMenuItems } = useProcessMenuItems({});

  return {
    themenMenuItems: processMenuItems(themenItems),
    pagesMenuItems: processMenuItems(pagesItems),
  };
};
