// extracted by mini-css-extract-plugin
export var advantagis__item = "psychologist-module--advantagis__item--24543";
export var psychologist = "psychologist-module--psychologist--6f643";
export var psychologist__advantagis = "psychologist-module--psychologist__advantagis--86daf";
export var psychologist__content = "psychologist-module--psychologist__content--a2059";
export var psychologist__header = "psychologist-module--psychologist__header--163b0";
export var psychologist__headerTitle = "psychologist-module--psychologist__header--title--8b822";
export var psychologist__slider = "psychologist-module--psychologist__slider--4c8aa";
export var psychologist__slider__button = "psychologist-module--psychologist__slider__button--4729c";
export var psychologist__slider__button__next = "psychologist-module--psychologist__slider__button__next--86799";
export var psychologist__slider__button__prev = "psychologist-module--psychologist__slider__button__prev--f4edb";
export var slide = "psychologist-module--slide--4a270";
export var slideContainer = "psychologist-module--slide-container--94e57";
export var slide__degrees = "psychologist-module--slide__degrees--c22db";
export var slide__image = "psychologist-module--slide__image--9cdbc";
export var slide__imageContainer = "psychologist-module--slide__image-container--36e8d";
export var slide__imageLicense = "psychologist-module--slide__image--license--3ab84";
export var slide__left = "psychologist-module--slide__left--e49af";
export var slide__name = "psychologist-module--slide__name--fcf84";
export var slide__nextArrow = "psychologist-module--slide__next-arrow--b2f63";
export var slide__prevArrow = "psychologist-module--slide__prev-arrow--5e5de";
export var slide__right = "psychologist-module--slide__right--a3a46";
export var slide__text = "psychologist-module--slide__text--50834";
export var slide__textFirstLine = "psychologist-module--slide__textFirstLine--d226e";