import React, { useMemo } from 'react';

import Mail from '~/assets/images/icons/footer/mail.svg';
import Phone from '~/assets/images/icons/footer/phone.svg';
import { Container } from '~/shared/components';
import * as styles from '~/widgets/footer/footer.module.scss';

const EMAIL_CONTACT_TYPE = 'email';
const PHONE_CONTACT_TYPE = 'phone';

const iconsMap = {
  [EMAIL_CONTACT_TYPE]: Mail,
  [PHONE_CONTACT_TYPE]: Phone,
};
const linkMap = {
  [EMAIL_CONTACT_TYPE]: (info) => `mailto:${info}`,
  [PHONE_CONTACT_TYPE]: (info) => `tel:${info}`,
};

export const FooterContact = ({ contactType, contactLabel, contactInfo }) => {
  const icon = useMemo(() => iconsMap[contactType], [contactType]);
  const linkPerType = useMemo(() => linkMap[contactType](contactInfo), [contactType, contactInfo]);

  return (
    <Container className={styles.footer__contactsLink}>
      <img src={icon} alt={contactType} />
      <a href={linkPerType} target={'_blank'} rel="noreferrer">
        {contactLabel}
      </a>
    </Container>
  );
};
