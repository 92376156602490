import { useEffect, useState } from 'react';

const BUSINESS_PAGE_ENUM_VALUE = 'business';
export const useIsBusinessPage = ({ pageContext }) => {
  const [isBusiness, setIsBusiness] = useState(false);
  useEffect(() => {
    const { pageFor } = pageContext;
    setIsBusiness(pageFor === BUSINESS_PAGE_ENUM_VALUE);
  }, [pageContext]);
  return isBusiness;
};
