import React, { useMemo } from 'react';

import { applicationSettings } from '~/shared/application.settings';
import { getSrc } from 'gatsby-plugin-image';

const supportedSocialNetworks = {
  Facebook: 'Facebook',
  Twitter: 'Twitter',
};

const FacebookMeta = React.memo(({ metaSocial, metaImageSrc, metaUrl }) => (
  <>
    <meta property="og:title" content={metaSocial.title} />
    <meta property="og:description" content={metaSocial.description} />
    {metaImageSrc ? <meta property="og:image" content={metaImageSrc} /> : <></>}
    {metaUrl ? <meta property="og:url" content={metaUrl} /> : <></>}
  </>
));
const TwitterMeta = React.memo(({ metaSocial, metaImageSrc }) => (
  <>
    <meta name="twitter:card" content="" />
    <meta name="twitter:title" content={metaSocial.title} />
    <meta name="twitter:description" content={metaSocial.description} />
  </>
));

const MetaPerSocialNetwork = {
  [supportedSocialNetworks.Facebook]: FacebookMeta,
  [supportedSocialNetworks.Twitter]: TwitterMeta,
};

export const SeoHead = ({
  metaTitle,
  metaDescription,
  metaSocial: metaSocials,
  canonicalURL,
  metaRobots,
  keywords,
  pathname,
  children,
}) => {
  const fullUrl = useMemo(() => (pathname ? `${applicationSettings.SITE_DOMAIN}${pathname}` : null), [pathname]);
  const renderMetaSocial = useMemo(
    () =>
      metaSocials?.map((metaSocial) => {
        const imageSource = metaSocial.image?.localFile
          ? `${applicationSettings.SITE_DOMAIN}${getSrc(metaSocial.image?.localFile)}`
          : null;
        const MetaComponent = MetaPerSocialNetwork?.[metaSocial.socialNetwork] || <></>;
        return (
          <MetaComponent
            key={metaSocial.socialNetwork}
            metaSocial={metaSocial}
            metaImageSrc={imageSource}
            metaUrl={fullUrl}
          />
        );
      }),
    [metaSocials]
  );
  return (
    <>
      <html lang="de" />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      {metaRobots ? <meta name="robots" content={metaRobots} /> : <></>}
      {keywords ? <meta name="keywords" content={keywords} /> : <></>}
      {canonicalURL ? <link rel="canonical" href={canonicalURL} /> : <></>}
      {renderMetaSocial}
      {children}
    </>
  );
};

SeoHead.defaultProps = {
  pathname: null,
};
