import React from 'react';

import { Container } from '~/shared/components';
import clsx from 'clsx';

import * as styles from './spinner.module.scss';

export const Spinner = React.memo(({ isLoading, fullPage }) => {
  if (!isLoading) return <></>;
  return (
    <Container className={clsx(styles.spinnerContainer, fullPage ? styles.spinnerContainerFull : '')}>
      <Container className={styles.loadingSpinner} />
    </Container>
  );
});

Spinner.defaultProps = {
  isLoading: true,
  fullPage: true,
};
