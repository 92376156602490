import React from 'react';

import { Container, Heading, Section } from '~/shared/components';

import { AdvantageBusinessItem } from './advantage.business.item';
import * as styles from './advantages.module.scss';

export const AdvantagesBusinessSection = ({ blockTitle, button, items }) => {
  const itemsRender = items.map((item) => (
    <AdvantageBusinessItem key={item.title} type={item.iconType} title={item.title} description={item.description} />
  ));
  return (
    <>
      <Section title={blockTitle} className={styles.advantages}>
        <Container className={styles.advantages__content}>
          <Heading variant={2} className={styles.advantages__content__title}>
            {blockTitle}
          </Heading>
          <Container className={styles.advantages__content__advantages}>{itemsRender}</Container>
        </Container>
      </Section>
    </>
  );
};
