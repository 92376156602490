import React from 'react';

import { Container, GoBackButton, Heading, Section } from '~/shared/components';
import { PostDate } from '~/shared/components/ui/elements/date-block';
import clsx from 'clsx';
import { Link } from 'gatsby-plugin-intl';

import * as styles from './post.module.scss';

export default ({ children, postData }) => (
  <Section className={styles.blogAll} title={postData.title}>
    <Container className={'wrapper-small'}>
      <Container className={styles.blogAll__content}>
        <GoBackButton />
        <Heading variant={1} className={styles.blogAll__title}>
          {postData.title}
        </Heading>
        <Container className={styles.blogAll__filter}>
          <Link to={`/posts/categories/${postData.postCategory.slug}`} className={clsx(styles.blogAll__filterItem)}>
            {postData.postCategory.title}
          </Link>
          <PostDate className={styles.blogAll__date} postDate={postData.publishedAt} />
        </Container>
      </Container>
      {children}
    </Container>
  </Section>
);
