import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFormContext } from 'react-hook-form';

import { useMutation } from '@tanstack/react-query';
import { applicationSettings } from '~/shared/application.settings';
import { Container } from '~/shared/components';
import { verifyCaptcha } from '~/shared/components/form/recaptcha/hooks/model';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import * as styles from './recaptcha.module.scss';

const CaptchaHandler = ({ onVerifyCaptcha, action }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState(null);
  const { formatMessage } = useIntl();

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: async (sourceToken) => {
      return await verifyCaptcha({ token: sourceToken });
    },
    mutationKey: 'verifyCaptcha',
  });

  useEffect(() => {
    if (isSuccess) onVerifyCaptcha(token);
  }, [isSuccess]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      return;
    }
    if (isLoading) return;
    const sourceToken = await executeRecaptcha(action);
    await mutate(sourceToken);
    setToken(sourceToken);
  };
  if (!isError) return <></>;
  return (
    <Container className={styles.recaptcha__error}>
      <FormattedMessage
        id="recaptcha.bot-detected"
        defaultMessage="Eine verdächtige Aktivität festgestellt wurde. Klicken Sie auf den {link}, um es erneut zu versuchen."
        values={{
          link: (
            <a className={styles.recaptcha__errorLink} onClick={handleReCaptchaVerify}>
              {formatMessage({ id: 'recaptcha.bot-detected.link' })}
            </a>
          ),
        }}
      />
    </Container>
  );
};

export const ReCaptcha = ({ action, name }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const onVerifyCaptcha = (token) => {
    setValue(name, token);
  };

  return <CaptchaHandler action={action} onVerifyCaptcha={onVerifyCaptcha} />;
};
