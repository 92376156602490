import React, { useMemo } from 'react';

import Facebook from '~/assets/images/icons/footer/facebook.svg';
import Insta from '~/assets/images/icons/footer/insta.svg';

const FACEBOOK_CONTACT_TYPE = 'facebook';
const INSTAGRAM_CONTACT_TYPE = 'instagram';
const TWITTER_CONTACT_TYPE = 'twitter/x';
const LINKEDIN_CONTACT_TYPE = 'linkedin';

const iconsMap = {
  [FACEBOOK_CONTACT_TYPE]: Facebook,
  [INSTAGRAM_CONTACT_TYPE]: Insta,
  [TWITTER_CONTACT_TYPE]: Facebook, //TODO add new
  [LINKEDIN_CONTACT_TYPE]: Insta,
};

const linkMap = {
  [FACEBOOK_CONTACT_TYPE]: (link) => link,
  [INSTAGRAM_CONTACT_TYPE]: (link) => link,
  [TWITTER_CONTACT_TYPE]: (link) => link,
  [LINKEDIN_CONTACT_TYPE]: (link) => link,
};

export const FooterSocial = ({ social, socialLink }) => {
  const icon = useMemo(() => iconsMap[social], [social]);
  const linkPerType = useMemo(() => linkMap[social](socialLink), [socialLink, social]);

  return (
    <a href={linkPerType} target={'_blank'} rel="noreferrer">
      <img src={icon} alt={'logo'} />
    </a>
  );
};
