import React, { useCallback, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';

import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '~/shared/components/form';
import isEmpty from 'lodash.isempty';
import isObject from 'lodash.isobject';

export const ConnectForm = ({ children }) => {
  const methods = useFormContext();
  return children(methods);
};

export const useFieldArrayContext = (name) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });
  return { fields, append, remove };
};

// eslint-disable-next-line react/display-name
export const BaseForm = React.memo(({ defaultValues, children, onSubmit, resolver, className, id }) => {
  const methods = useForm({ defaultValues, resolver: yupResolver(resolver), mode: 'onBlur' });
  const [globalFormError, setGlobalFormError] = useState({ message: null });
  const handleValidationErrors = useCallback(
    (errors, callback = (_) => true) => {
      if (!isObject(errors) || isEmpty(errors)) return;
      const formError = callback(errors);
      if (formError && !isEmpty(formError)) {
        setGlobalFormError({ message: formError });
        return;
      }

      return Reflect.ownKeys(errors).map((fieldName) => {
        const errorsToProcess = errors?.[fieldName];
        if (isObject(errorsToProcess)) {
          const types = errorsToProcess.reduce((result, errorMessage) => {
            result.push(errorMessage);
            return result;
          }, []);
          methods.setError(fieldName, { types: types });
        }
      });
    },
    [defaultValues]
  );

  return (
    <FormProvider {...methods}>
      <form
        id={id}
        onSubmit={methods.handleSubmit(
          (attrs) => onSubmit({ attrs, handleValidationErrors }),
          () => {}
        )}
        autoComplete="off"
        className={className}
      >
        <ConnectForm>{(methods) => children({ ...methods }, handleValidationErrors)}</ConnectForm>
      </form>
      <ErrorMessage errors={globalFormError} />
      <DevTool control={methods.control} />
    </FormProvider>
  );
});
