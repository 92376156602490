import React, { useMemo } from 'react';

import { MarkdownSection } from '~/widgets/sections/common.sections/markdown';

import * as styles from './markdown.module.scss';

export const MarkDownPost = ({ content }) => {
  const contentImages = useMemo(
    () => content?.content.medias.map((media) => ({ url: media.url, ...media.localFile })),
    [content]
  );
  return (
    <MarkdownSection
      className={styles.postMarkdown}
      content={{ data: content?.content.data.content }}
      contentImages={contentImages}
    />
  );
};
