import React from 'react';

import { Dropdown } from '~/shared/components';
import { changeLocale, IntlContextConsumer } from 'gatsby-plugin-intl';

export const LanguageSwitcher = React.memo(() => (
  <IntlContextConsumer>
    {({ languages, language: currentLocale }) => {
      if (languages.length === 1) return <></>;
      return (
        <Dropdown
          options={languages.map((language) => ({ title: language, value: language }))}
          active={currentLocale}
          onChange={changeLocale}
        />
      );
    }}
  </IntlContextConsumer>
));
