import React, { useLayoutEffect } from 'react';

import { SettingsProvider } from '~/app/providers';
import { HandleBroadcastEvents, ProlongateSession, useAuth } from '~/features/auth';
import { useScrollRestoration } from '~/shared';
import { PageFragment } from '~/shared/api/graphql.fragments';
import { Container, IF } from '~/shared/components';
import { FooterSection } from '~/widgets/footer';
import { Header } from '~/widgets/header';

import * as styles from './layout.module.scss';

/**
 * Renders the public layout for the application.
 *
 * @param {Object} location - the location object
 * @param {Object} pageContext - the page context object
 * @param {ReactNode} children - the child components to be rendered
 * @return {React.JSX.Element} - the public layout component
 */
export const PublicLayout = ({ location, pageContext, children }) => {
  const { syncJwt, isLoggedIn, isPending } = useAuth();
  const { restoreScroll } = useScrollRestoration({ ignorePagesWithPrefix: '/app' });

  useLayoutEffect(() => {
    syncJwt();
    restoreScroll();
  }, []);

  if (isPending) return <></>;
  return (
    <>
      <PageFragment />
      <HandleBroadcastEvents channel={'login_channel'} loginAction={syncJwt} logoutAction={syncJwt} />
      <Container className={styles.container}>
        <SettingsProvider>
          <Header pageContext={pageContext} location={location} />
          <main className={'content-grid'}>{children}</main>
          <FooterSection />
        </SettingsProvider>
      </Container>

      <IF condition={isLoggedIn}>
        <ProlongateSession action={syncJwt} interval={60 * 1000} />
      </IF>
    </>
  );
};
