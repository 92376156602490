import React from 'react';

import { Container, Heading, Image, Section, Slider } from '~/shared/components';
import { baseSectionType, gatsbyImageType } from '~/widgets/sections/typed';
import PropTypes from 'prop-types';
import { SwiperSlide } from 'swiper/react';

import * as styles from './partners.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */

export const PartnersSection = ({ blockTitle, title, logo, subTitle }) => {
  return (
    <Section title={blockTitle} className={styles.partners}>
      <Container>
        <Heading variant={2} className={styles.partners__title}>
          {title ? title : blockTitle}
        </Heading>
        <p className={styles.partners__description}>{subTitle}</p>
        <Container className={styles.partners__list}>
          {logo.map((image) => (
            <Container className={styles.partners__listItem} key={image.altText}>
              <Image objectFit="contain" src={image.imageFile} alt={image.altText} className={styles.partners__logo} />
            </Container>
          ))}
        </Container>
        <Container className={styles.partners__listMobile}>
          <Slider slidesPerView={1} count={logo.length} navigation={false} pagination={true}>
            {logo.map((image) => (
              <SwiperSlide key={image.altText}>
                <Container className={styles.partners__imageWrapper}>
                  <Image
                    objectFit={'contain'}
                    key={image.altText}
                    src={image.imageFile}
                    alt={image.altText}
                    className={styles.partners__logoMobile}
                  />
                </Container>
              </SwiperSlide>
            ))}
          </Slider>
        </Container>
      </Container>
    </Section>
  );
};

PartnersSection.propTypes = {
  baseSectionType,
  logo: PropTypes.arrayOf(gatsbyImageType),
};
