// extracted by mini-css-extract-plugin
export var benefits = "benefits-module--benefits--db27e";
export var benefitsBlock = "benefits-module--benefits--block--38a31";
export var benefitsBlockImage = "benefits-module--benefits--block--image--4201e";
export var benefitsBlockImage__container = "benefits-module--benefits--block--image__container--1276b";
export var benefitsBlockImage__translated = "benefits-module--benefits--block--image__translated--322f6";
export var benefitsBlock__first = "benefits-module--benefits--block__first--9ec8a";
export var benefitsBlock__heading = "benefits-module--benefits--block__heading--81569";
export var benefitsSection = "benefits-module--benefits--section--a56f8";
export var benefits__bottom = "benefits-module--benefits__bottom--9880b";
export var benefits__bottomBlock = "benefits-module--benefits__bottom--block--02822";
export var benefits__bottomBlock__title = "benefits-module--benefits__bottom--block__title--48347";
export var benefits__bottomBlock__titleBlock = "benefits-module--benefits__bottom--block__title-block--8122e";
export var benefits__bottom__secondBackground = "benefits-module--benefits__bottom__second--background--9b25c";
export var benefits__bottom__thirdBackground = "benefits-module--benefits__bottom__third--background--ac786";