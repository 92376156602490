import React from 'react';

import { ButtonLink, Container, Heading, Section } from '~/shared/components';
import { buttonType, gatsbyImageType } from '~/widgets/sections/typed';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import * as styles from './about.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const AboutUsBanner = ({ blockTitle, title, description, button }) => {
  return (
    <Section title={blockTitle} className={clsx('full-width', styles.aboutUsBanner)}>
      <Container className={styles.aboutUsBanner__content}>
        <Container className={styles.aboutUsBanner__contentLeft}>
          <Heading variant={2}>{title ? title : blockTitle}</Heading>
        </Container>
        <Container className={styles.aboutUsBanner__action}>
          <p>{description}</p>
          <ButtonLink
            id={'about-us-section-button'}
            navigateTo={button.page?.slug}
            fallback={button.buttonLink}
            title={button.buttonText}
            variant={button.buttonVariant}
          />
        </Container>
        <span className={styles.aboutUsBanner__decoration_circle}></span>
        <span className={styles.aboutUsBanner__decoration_equals}></span>
        <span className={styles.aboutUsBanner__decoration_lines}></span>
      </Container>
    </Section>
  );
};

AboutUsBanner.propTypes = {
  title: PropTypes.string.isRequired,
  image: gatsbyImageType,
  button: buttonType.isRequired,
};
