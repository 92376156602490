import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import '~/assets/styles/global.scss';
import '~/assets/styles/grid.css';
import { AuthProvider, QueryProvider } from '~/app/providers';
import { applicationSettings } from '~/shared/application.settings';
import scrollTo from 'gatsby-plugin-smoothscroll';

export const wrapRootElement = ({ element }) => (
  <React.StrictMode>
    <QueryProvider>
      <AuthProvider>
        <GoogleReCaptchaProvider reCaptchaKey={applicationSettings.CAPTCHA_SITE}>{element}</GoogleReCaptchaProvider>
      </AuthProvider>
    </QueryProvider>
  </React.StrictMode>
);

export const shouldUpdateScroll = ({ routerProps, ...props }) => {
  const { location } = routerProps;
  const { hash, state } = location;
  if (!state) return true;
  if (hash) {
    setTimeout(() => scrollTo(hash, 'start'), 110);
    return false;
  }
  setTimeout(() => scrollTo('body', 'start'), 110);

  return false;
};

export const onClientEntry = (...args) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
};
