import React, { useMemo, useRef } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './slider.css';
import arrow from '~/assets/images/icons/slider/arrow2.svg';
import { Container } from '~/shared/components';
import { NextArrowButton, PrevArrowButton } from '~/shared/components/ui/elements/slider/elements/slider.buttons';
import clsx from 'clsx';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper } from 'swiper/react';

import * as styles from './container.module.scss';

export const Slider = React.memo(
  ({
    count,
    children = null,
    responsiveSettings = {},
    infinite = true,
    navigation = true,
    autoplay = false,
    transitionDuration = 300,
    rowSize = 3,
    canSwipe = false,
    className = '',
    pagination = true,
    slidesPerView = 1,
    breakpoints = {},
    spaceBetween = 0,
    outSideBtn = false,
    prevArrowClass = '',
    nextArrowClass = '',
    bottomSpaceClass = '',
  }) => {
    if (!children || count < 1) return <></>;
    const paginationTemplate = {
      clickable: true,
    };

    const navigationTemplate = {
      enabled: true,
      prevEl: styles.slider__button__left,
      nextEl: styles.slider__button__right,
    };

    /**
     * Generate all slider configuration parameters
     * @type {{nextArrow, infinite: boolean, prevArrow, responsive: *[], arrows: boolean, transitionDuration: number, indicators: function(*): *, autoplay: boolean}}
     */
    const properties = useMemo(
      () => ({
        modules: [Navigation, Pagination],
        navigation: navigation,
        pagination: pagination && paginationTemplate,
        loop: infinite,
        speed: transitionDuration,
        breakpoints: breakpoints,
        spaceBetween: spaceBetween,
        outSideBtn: outSideBtn,
        prevArrowClass: prevArrowClass,
        nextArrowClass: nextArrowClass,
        bottomSpaceClass: bottomSpaceClass,
      }),
      []
    );
    const swiperRef = useRef();
    return (
      <Container className={styles.slider}>
        {properties.outSideBtn && (
          <>
            <PrevArrowButton
              action={() => swiperRef.current?.slidePrev()}
              arrow={arrow}
              className={properties.prevArrowClass}
            />
          </>
        )}
        <Swiper
          {...properties}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {children}
        </Swiper>
        {properties.outSideBtn && (
          <>
            <NextArrowButton
              action={() => swiperRef.current?.slideNext()}
              arrow={arrow}
              className={properties.nextArrowClass}
            />
          </>
        )}
      </Container>
    );
  }
);
