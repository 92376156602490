import React from 'react';

import { ButtonLink, Container, Heading, Section } from '~/shared/components';

import * as styles from './contact.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const ContactUsSection = ({ blockTitle, title, contactText, button }) => {
  return (
    <Section title={blockTitle}>
      <Container className={styles.contactUs__content}>
        <Heading className={styles.contactUs__title} variant={2}>
          {title ? title : blockTitle}
        </Heading>
        <Container className={styles.contactUs__action}>
          <p>{contactText}</p>
          <ButtonLink
            id={'contact-us-section-button'}
            navigateTo={button.page?.slug}
            fallback={button.buttonLink}
            title={button.buttonText}
            variant={button.buttonVariant}
            size={'medium'}
          />
        </Container>
      </Container>
    </Section>
  );
};
