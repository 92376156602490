import React, { useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { demoRequestValidationSchemaLoader, SuccessContainer, useDemoRequestForm } from '~/features/contact-us';
import { BaseForm, Button, Container, Heading, IF, Section, Spinner } from '~/shared/components';
import { ButtonGroup } from '~/shared/components/button';
import { FormElement, FormInput, ReCaptcha, Textarea } from '~/shared/components/form';
import * as styles from '~/widgets/sections/common.sections/contact-us-form/form.module.scss';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';

export const DemoForm = ({
  blockTitle,
  formName,
  subTitle,
  successTitle,
  successDescription,
  successButton,
  button,
}) => {
  const { formatMessage } = useIntl();
  const [formIsSubmit, setFormIsSubmit] = useState(false);
  const { formSchema, isLoading, isSuccess, mutate, defaultValues } = useDemoRequestForm({
    formName,
    validationSchemaLoader: demoRequestValidationSchemaLoader,
  });
  useEffect(() => {
    setFormIsSubmit(isSuccess);
  }, [isSuccess]);

  const Form = () => {
    return (
      <>
        <BaseForm onSubmit={mutate} defaultValues={defaultValues} resolver={formSchema}>
          {({ register, control, formState, getValues }) => (
            <>
              <FormInput
                name="form"
                type={'hidden'}
                register={register}
                control={control}
                formState={formState}
                getValues={getValues}
              />
              <FormElement
                label={formatMessage({ id: 'validate.demo-request.form.field.name' })}
                labelBefore={formatMessage({ id: 'form.fields.required' })}
                variant={'underline'}
                name="name"
                className={styles.form__inputWrapper}
              >
                <FormInput
                  name="name"
                  register={register}
                  control={control}
                  formState={formState}
                  getValues={getValues}
                  className={styles.form__input}
                  disabled={false}
                />
              </FormElement>
              <FormElement
                label={formatMessage({ id: 'validate.demo-request.form.field.companyName' })}
                labelBefore={formatMessage({ id: 'form.fields.required' })}
                variant={'underline'}
                name="companyName"
                className={styles.form__inputWrapper}
              >
                <FormInput
                  name="companyName"
                  register={register}
                  control={control}
                  formState={formState}
                  getValues={getValues}
                  className={styles.form__input}
                  disabled={false}
                />
              </FormElement>
              <FormElement
                label={formatMessage({ id: 'validate.demo-request.form.field.email' })}
                labelBefore={formatMessage({ id: 'form.fields.required' })}
                variant={'underline'}
                name="email"
                className={styles.form__inputWrapper}
              >
                <FormInput
                  name="email"
                  register={register}
                  control={control}
                  formState={formState}
                  getValues={getValues}
                  className={styles.form__input}
                  disabled={false}
                />
              </FormElement>
              <FormElement
                label={formatMessage({ id: 'validate.demo-request.form.field.telephoneNumber' })}
                labelBefore={formatMessage({ id: 'form.fields.required' })}
                variant={'underline'}
                name="telephoneNumber"
                className={styles.form__inputWrapper}
              >
                <FormInput
                  name="telephoneNumber"
                  type="number"
                  register={register}
                  control={control}
                  formState={formState}
                  getValues={getValues}
                  className={styles.form__input}
                  disabled={false}
                />
              </FormElement>
              <FormElement
                label={formatMessage({ id: 'validate.demo-request.form.field.qntEmployees' })}
                labelBefore={formatMessage({ id: 'form.fields.required' })}
                variant={'underline'}
                name="qntEmployees"
                className={styles.form__inputWrapper}
              >
                <FormInput
                  name="qntEmployees"
                  type="number"
                  register={register}
                  control={control}
                  formState={formState}
                  getValues={getValues}
                  className={styles.form__input}
                  disabled={false}
                />
              </FormElement>
              <FormElement
                label={formatMessage({ id: 'validate.demo-request.form.field.message' })}
                labelBefore={formatMessage({ id: 'form.fields.optional' })}
                variant={'underline'}
                name="message"
                className={styles.form__inputWrapper}
              >
                <Textarea
                  name="message"
                  rows={2}
                  register={register}
                  control={control}
                  formState={formState}
                  getValues={getValues}
                  className={styles.form__textarea}
                  placeholder={formatMessage({ id: 'form.fields.textarea.placeholder' })}
                  disabled={false}
                />
              </FormElement>
              <FormElement name="">
                <FormInput
                  name="token"
                  type={'hidden'}
                  register={register}
                  control={control}
                  formState={formState}
                  getValues={getValues}
                  disabled={false}
                />
              </FormElement>
              <ButtonGroup className={styles.form__buttonWrapper}>
                <Container className={styles.form__buttonWrapperRecaptcha}>
                  <ReCaptcha name="token" action={'demobusiness'} />
                  <Button
                    type={'submit'}
                    variant={button?.buttonVariant}
                    size={'small'}
                    title={button?.buttonText}
                    disabled={isLoading}
                    isLoading={isLoading}
                  />
                </Container>
              </ButtonGroup>
            </>
          )}
        </BaseForm>
      </>
    );
  };

  return (
    <Section title={blockTitle} className={styles.form}>
      <Container className={clsx(styles.form__content)}>
        <Container className={styles.form__content_left}>
          <Heading variant={1} className={styles.form__title}>
            {blockTitle}
          </Heading>
          <p>{subTitle}</p>
        </Container>
        <Container className={styles.form__content_right}>
          <SwitchTransition>
            <CSSTransition key={[formIsSubmit, isLoading]} timeout={500} classNames="fade">
              <>
                <IF condition={isLoading}>
                  <Spinner />
                </IF>
                <IF condition={!formIsSubmit && !isLoading}>
                  <Form />
                </IF>
                <IF condition={formIsSubmit}>
                  <SuccessContainer
                    successTitle={successTitle}
                    successDescription={successDescription}
                    successButton={successButton}
                    onClick={() => setFormIsSubmit(false)}
                  />
                </IF>
              </>
            </CSSTransition>
          </SwitchTransition>
        </Container>
      </Container>
    </Section>
  );
};
