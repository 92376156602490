import React from 'react';

import { Container, Heading, Image, Section } from '~/shared/components';
import { baseSectionType, gatsbyImageType } from '~/widgets/sections/typed';
import clsx from 'clsx';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';

import * as styles from './header.module.scss';

export const AboutUsHeaderSection = ({ blockTitle, backgroundImage, backgroundImageMobile, title, description }) => {
  return (
    <Section title={blockTitle} className={clsx(styles.header, 'full-width')}>
      <BackgroundImage
        className={clsx(styles.header__image, 'full-width', 'desktop')}
        fluid={backgroundImage.imageFile.childImageSharp.fluid}
      >
        <Image
          objectFit="contain"
          loading={'eager'}
          className={clsx(styles.header__image, 'full-width')}
          src={backgroundImage.imageFile}
          alt={backgroundImage.altText}
        />
      </BackgroundImage>
      <BackgroundImage
        className={clsx(styles.header__image, 'full-width', 'mobile')}
        fluid={backgroundImageMobile.imageFile.childImageSharp.fluid}
        style={{
          backgroundPosition: 'bottom',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
          backgroundPositionY: 'bottom',
        }}
      />

      <Container className={styles.header__contentWrapper}>
        <Container className={styles.header__content}>
          <Heading variant={1} className={styles.header__title}>
            {title ? title : blockTitle}
          </Heading>
          <p>{description}</p>
        </Container>
      </Container>
    </Section>
  );
};

AboutUsHeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  backgroundImage: gatsbyImageType,
  baseSectionType,
};
