import React from 'react';

import { navigate } from '@reach/router';
import { Button } from '~/shared/components';

export const GoBackButton = React.memo(({ title }) => (
  <Button title={title} variant={'invisible'} onClick={() => navigate(-1)} />
));

GoBackButton.defaultProps = {
  title: '',
};
