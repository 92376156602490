import React from 'react';

import { ButtonLinkOnlyNotLoggedIn } from '~/features/auth';
import { Container, Section } from '~/shared/components';

import * as styles from './test-navigation.module.scss';

export const TestNavigationSection = ({ button }) => (
  <Section title={'test-navigation'} className={styles.testNavigationSection}>
    <ButtonLinkOnlyNotLoggedIn
      id={'navigate-to-test-section-button'}
      navigateTo={button.page?.slug}
      fallback={button.buttonLink}
      title={button.buttonText}
      variant={button.buttonVariant}
      size={'medium'}
      external={true}
    />
    <Container className={styles.testNavigation__line} />
  </Section>
);
