import { useCallback, useEffect, useMemo } from 'react';

import { useLocation } from '@reach/router';

export const useScrollRestoration = ({ enableTracking = true, ignorePagesWithPrefix = '' }) => {
  const { pathname } = useLocation();
  const hashCode = (s) =>
    s.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);

  const pageIdentity = useMemo(() => {
    return hashCode(pathname);
  }, [pathname]);

  const storagePageKey = useMemo(() => {
    return `scrollRestoration::top-${pageIdentity}`;
  }, [pageIdentity]);

  const allowTracking = useMemo(() => {
    return enableTracking && !pathname.includes(ignorePagesWithPrefix);
  }, [enableTracking, ignorePagesWithPrefix, pathname]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!allowTracking) return;
    window.removeEventListener('scroll', saveScrollPosition);
    window.addEventListener('scroll', saveScrollPosition);

    return () => {
      window.removeEventListener('scroll', saveScrollPosition);
    };
  }, [storagePageKey]);

  const saveScrollPosition = useCallback(() => {
    if (typeof window === 'undefined') return;
    if (!allowTracking) return;
    sessionStorage.setItem(storagePageKey, window.pageYOffset);
    sessionStorage.setItem('lastPageKey', storagePageKey);
    sessionStorage.setItem('returnToPathName', pathname);
  }, [storagePageKey]);

  const restoreScroll = useCallback(() => {
    if (typeof window === 'undefined') return;
    const scrollRestoration = sessionStorage.getItem(storagePageKey);
    if (!scrollRestoration || sessionStorage.getItem('lastPageKey') !== storagePageKey) return;

    setTimeout(() => {
      window.scrollTo({ top: scrollRestoration, behavior: 'smooth' });
      sessionStorage.removeItem(storagePageKey);
    }, 500);
  }, [pageIdentity]);

  return {
    restoreScroll,
    saveScrollPosition,
  };
};
