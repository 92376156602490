import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';

import { ExternalLinkComponent, Heading, Image } from '~/shared/components';
import clsx from 'clsx';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';

import * as styles from './markdown.block.module.scss';

export const Markdown = ({ sectionId, contentImages, content: { data }, className = '' }) => {
  const getImageSource = useCallback((src) => contentImages.find(({ url }) => url === src), [sectionId]);
  return (
    <ReactMarkdown
      className={className}
      remarkPlugins={[gfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        h1: ({ level, children }) => (
          <Heading variant={level} className={clsx(styles.markdown__heading, styles.markdown__headingH1)}>
            {children}
          </Heading>
        ),
        h2: ({ level, children }) => (
          <Heading variant={level} className={clsx(styles.markdown__heading, styles.markdown__headingH2)}>
            {children}
          </Heading>
        ),
        h3: ({ level, children }) => (
          <Heading variant={level} className={clsx(styles.markdown__heading, styles.markdown__headingH3)}>
            {children}
          </Heading>
        ),
        img: ({ src, alt }) => (
          <div className={styles.imageBlock}>
            <Image src={getImageSource(src)} alt={alt} objectFit={'contain'}></Image>
          </div>
        ),
        u: ({ children }) => <u className={styles.textUnderline}>{children}</u>,
        ul: ({ children }) => <ul className={styles.bulletList}>{children}</ul>,
        ol: ({ children }) => <ol className={styles.numbersList}>{children}</ol>,
        blockquote: ({ children, ...res }) => <blockquote className={styles.blockquote}>{children}</blockquote>,
        pre: ({ children }) => <pre className={styles.code}>{children}</pre>,
        a: ({ children, href }) => (
          <ExternalLinkComponent className={styles.link} to={href}>
            {children}
          </ExternalLinkComponent>
        ),
        p: ({ children }) => <p className={styles.paragraph}>{children}</p>,
        br: ({ ..._ }) => <p>&nbsp;</p>,
      }}
    >
      {data}
    </ReactMarkdown>
  );
};
