import React, { useMemo } from 'react';

import { ButtonLinkOnlyNotLoggedIn } from '~/features/auth';
import { Container, Section, SectionTitle } from '~/shared/components';
import Step from '~/widgets/sections/common.sections/support/step';
import { buttonType } from '~/widgets/sections/typed';
import PropTypes from 'prop-types';

import * as styles from './support.module.scss';

export const SupportSection = ({ blockTitle, title, button, supportBlock }) => {
  const supportBlockRender = useMemo(
    () =>
      supportBlock.map((block, position) => (
        <Step key={block.title} position={position} title={block.title} description={block.description} />
      )),
    [supportBlock]
  );
  return (
    <Section title={blockTitle} className={styles.steps}>
      <Container className={styles.steps__content}>
        <SectionTitle variant={2} className={styles.steps__title}>
          {title ? title : blockTitle}
        </SectionTitle>
        <Container className={styles.steps__list}>{supportBlockRender}</Container>
        <ButtonLinkOnlyNotLoggedIn
          id={'support-section-test-button'}
          navigateTo={button.page?.slug}
          fallback={button.buttonLink}
          title={button.buttonText}
          variant={button.buttonVariant}
        />
      </Container>
    </Section>
  );
};

SupportSection.propTypes = {
  title: PropTypes.string,
  button: buttonType,
  supportBlock: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};
