import React from 'react';

import { Container, Markdown, Section } from '~/shared/components';
import clsx from 'clsx';

import * as styles from './markdown.module.scss';

export const MarkdownSection = React.memo(({ sectionId, contentImages, content, className }) => (
  <Container className={clsx(styles.wrapperSmallMarkdown, styles.alignCenter)}>
    <Section title={'markdown'} className={className}>
      <Markdown
        className={clsx(styles.markdown)}
        sectionId={sectionId}
        contentImages={contentImages}
        content={content}
      />
    </Section>
  </Container>
));
