import React, { useCallback, useRef } from 'react';

import { AuthContext } from '~/shared/context/auth.context';

/**
 * Generates a custom hook for managing and subscribing to store data.
 *
 * @return {Object} An object containing getter, setter, and subscribe methods.
 */
const useStoreData = (initialValue = {}) => {
  const store = useRef(initialValue);

  // getter method which retrieves store value
  const get = useCallback(() => store.current, []);

  // store the callback which subscribes the store
  const subscribers = useRef(new Set());

  // setter method which update the store value and calls the subscribe function
  const set = useCallback((value) => {
    store.current = { ...store.current, ...value };
    return subscribers.current.forEach((callback) => callback());
  }, []);

  // subscribe method which adds callback to subscribers
  // and returns the cleanup function
  const subscribe = useCallback((callback) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return { get, set, subscribe };
};
export const AuthProvider = ({ children }) => (
  <AuthContext.Provider value={useStoreData({ jwt: false })}>{children}</AuthContext.Provider>
);
