import React from 'react';

import attention from '~/assets/images/icons/advantages/attention.svg';
import camera from '~/assets/images/icons/advantages/camera.svg';
import money from '~/assets/images/icons/advantages/money.svg';
import repeat from '~/assets/images/icons/advantages/repeat.svg';
import shield from '~/assets/images/icons/advantages/shield.svg';
import support from '~/assets/images/icons/advantages/support.svg';
import time from '~/assets/images/icons/advantages/time.svg';

const iconsPerType = { attention, camera, time, repeat, shield, money, support };

export const AdvantageImage = ({ iconType, className }) => (
  <img className={className} src={iconsPerType[iconType]} alt={'Advantage ' + iconType} />
);
