import React, { useMemo } from 'react';

import ICurve from '~/assets/images/main/curved-line.svg';
import { Container, Heading } from '~/shared/components';

import * as styles from './container.module.scss';

// eslint-disable-next-line react/display-name
export default ({ position, title, description }) => {
  const renderPosition = useMemo(() => (position + 1).toString().padStart(2, '0'), [position]);
  return (
    <Container className={styles.step}>
      <Container className={styles.step__number}>
        <span>{renderPosition}</span>
        {position === 0 ? <img src={ICurve} alt="curve line" /> : <></>}
      </Container>
      <Container className={styles.step__text}>
        <Heading variant={4} className={styles.step__title}>
          {title}
        </Heading>
        <p>{description}</p>
      </Container>
    </Container>
  );
};
