import React from 'react';

import { ButtonLinkOnlyNotLoggedIn } from '~/features/auth';
import { Container, Heading, Image, Section } from '~/shared/components';
import { baseSectionType, buttonType, gatsbyImageType } from '~/widgets/sections/typed';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

import * as styles from './welcome.module.scss';

const TitleDefault = ({ title }) => (
  <Heading variant={1} className={styles.welcome__title}>
    {title}
  </Heading>
);
const ImageDefault = ({ mainImage, loading = 'eager' }) => (
  <Image src={mainImage.imageFile} alt={mainImage.altText} loading={loading} />
);

const DesktopWelcomeImage = React.memo(({ mainImage, mainMobileImage, imageElement: ImageElement = ImageDefault }) => {
  return (
    <Container className={clsx(styles.welcome__content_right, mainMobileImage ? 'desktop' : '')}>
      <ImageElement mainImage={mainImage} />
    </Container>
  );
});

DesktopWelcomeImage.propTypes = {
  mainMobileImage: PropTypes.any,
  mainImage: PropTypes.any,
};

const MobileWelcomeImage = React.memo(({ mainMobileImage, imageElement: ImageElement = ImageDefault }) => {
  if (!mainMobileImage) return <></>;
  return (
    <Container
      className={clsx(
        styles.welcome__content_right,
        mainMobileImage ? 'mobile' : '',
        styles.welcome__content_rightMobile
      )}
    >
      <ImageElement mainImage={mainMobileImage} />
    </Container>
  );
});

MobileWelcomeImage.propTypes = { mainMobileImage: PropTypes.any };
export const WelcomeSection = ({
  button,
  mainImage,
  mainMobileImage,
  blockTitle,
  title,
  subTitle,
  className = 'full-width',
  titleElement: TitleElement = TitleDefault,
  imageElement: ImageElement = ImageDefault,
}) => {
  return (
    <Section title={blockTitle} className={clsx(styles.welcome, className)}>
      <Container className={styles.welcome__content}>
        <Container className={styles.welcome__content_left}>
          <TitleElement title={title ? title : blockTitle} />
          <p>{subTitle}</p>
          <ButtonLinkOnlyNotLoggedIn
            id={'welcome-section-to-test-button'}
            variant={button.buttonVariant}
            title={button.buttonText}
            navigateTo={button.page?.slug}
            fallback={button.buttonLink}
          />
        </Container>
        <DesktopWelcomeImage mainMobileImage={mainMobileImage} mainImage={mainImage} imageElement={ImageElement} />
        <MobileWelcomeImage mainMobileImage={mainMobileImage} imageElement={ImageElement} />
      </Container>
    </Section>
  );
};

WelcomeSection.propTypes = {
  button: buttonType,
  mainImage: gatsbyImageType,
  baseSectionType,
};

WelcomeSection.defaultProps = {
  className: 'full-width',
};
