import { useMemo } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

export const useLoadBlogPosts = ({ numberOfPosts }) => {
  const { locale, defaultLocale } = useIntl();

  const { itemsEn, itemsDe } = useStaticQuery(graphql`
    query blogItems {
      itemsEn: allStrapiBlogPost(
        sort: { publishedAt: DESC }
        filter: { locale: { eq: "en" }, publishedAt: { ne: null } }
      ) {
        ...PostsEdges
      }
      itemsDe: allStrapiBlogPost(
        sort: { publishedAt: DESC }
        filter: { locale: { eq: "de" }, publishedAt: { ne: null } }
      ) {
        ...PostsEdges
      }
    }
  `);
  const blogMap = {
    en: itemsEn,
    de: itemsDe,
  };

  return useMemo(() => {
    const items = blogMap?.[locale] || blogMap?.[defaultLocale];
    return items.edges.slice(0, numberOfPosts);
  }, [itemsEn, itemsDe]);
};
