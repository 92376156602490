import React from 'react';

import { ButtonLinkOnlyNotLoggedIn } from '~/features/auth';
import { Container, Heading, IF, Image, Section, Slider } from '~/shared/components';
import clsx from 'clsx';

import * as styles from './partners.module.scss';

export const PartnersSection = ({ blockTitle, button, items }) => {
  const ResponsiveSettings = [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];
  const renderItems = items.map(({ image, description }) => (
    <Container key={description} className={styles.partnersBusiness__content__partners__item}>
      <Container className={styles.partnersBusiness__content__partners__itemImage}>
        <Image src={image.imageFile} alt={image.alt} />
      </Container>
      <p>{description}</p>
    </Container>
  ));
  return (
    <Section title={blockTitle}>
      <Container className={styles.partnersBusiness__content}>
        <Container className={styles.partnersBusiness__contentTitle}>
          <Heading variant={2}>{blockTitle}</Heading>
        </Container>
        <Container
          className={clsx(
            styles.partnersBusiness__content__partners,
            styles.partnersBusiness__content__partnersHideMobile
          )}
        >
          {renderItems}
        </Container>
        <Container
          className={clsx(
            styles.partnersBusiness__content__partners__slider,
            styles.partnersBusiness__content__partnersShowMobile
          )}
        >
          <Slider count={items.length} arrows={false} alwaysIndicator={true} responsiveSettings={ResponsiveSettings}>
            {items.map(({ image, description }) => (
              <Container key={description} className={styles.partnersBusiness__content__partners__item}>
                <Container className={styles.partnersBusiness__content__partners__itemImage}>
                  <Image src={image.imageFile} alt={image.alt} />
                </Container>
                <p>{description}</p>
              </Container>
            ))}
          </Slider>
        </Container>
        <IF condition={button}>
          <ButtonLinkOnlyNotLoggedIn
            id={'partners-business-section-button'}
            fallback={button?.buttonLink}
            title={button?.buttonText}
            navigateTo={button.page?.slug}
            variant={button?.buttonVariant}
          />
        </IF>
      </Container>
    </Section>
  );
};
