import { useLocation } from '@reach/router';
import { validationSchemaLoader } from '~/features/contact-us/form/schema';
import { sendContactUsForm } from '~/features/contact-us/model';
import { useFormSubmit } from '~/shared/hooks/useFormSubmit';
import { useIntl } from 'gatsby-plugin-intl';

export const useContactUsForm = ({ formName }) => {
  const { formatMessage } = useIntl();
  const { href } = useLocation();
  const contactUsSchema = validationSchemaLoader({ formatMessage });

  const { mutate, isLoading, isSuccess } = useFormSubmit({
    action: async ({ attrs }) => await sendContactUsForm({ ...attrs, ...{ page: href }, form: formName }),
    onSuccess: () => {},
    onError: ({ errors, handleValidationErrors }) => {
      handleValidationErrors(errors);
      return true;
    },
    formName: formName,
  });

  return {
    formSchema: contactUsSchema,
    mutate,
    isLoading,
    defaultValues: {
      form: formName,
    },
    isSuccess,
  };
};
