import React from 'react';

import { ButtonLinkOnlyNotLoggedIn } from '~/features/auth';
import { Container, Heading, Image, Section } from '~/shared/components';
import Advantage from '~/widgets/sections/common.sections/advantages/advantage';
import { buttonType, gatsbyImageType } from '~/widgets/sections/typed';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import * as styles from './advantages.module.scss';

export const AdvantagesSection = ({ blockTitle, title, image, items, button }) => {
  const itemsRender = items.map((item) => (
    <Advantage key={item.title} type={item.iconType} title={item.title} description={item.description} />
  ));
  return (
    <Section title={blockTitle} className={styles.advantages}>
      <Container className={styles.advantages__content}>
        <Container className={clsx(styles.advantages__image_wrapper, 'desktop')}>
          <span className={styles.decoration_circle}></span>
          <span className={styles.decoration_lines}></span>
          <Image className={styles.advantages__image} src={image.imageFile} alt={image.altText} />
        </Container>
        <Container className={styles.advantages__description}>
          <Heading variant={2} className={styles.advantages__title}>
            {title ? title : blockTitle}
          </Heading>
          <Container className={styles.advantages__list}>{itemsRender}</Container>
          <ButtonLinkOnlyNotLoggedIn
            id={'advantages-section-test-button'}
            navigateTo={button.page?.slug}
            fallback={button.buttonLink}
            title={button.buttonText}
            variant={button.buttonVariant}
          />
        </Container>
      </Container>
    </Section>
  );
};

AdvantagesSection.propTypes = {
  blockTitle: PropTypes.string.isRequired,
  image: gatsbyImageType,
  button: buttonType.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      iconType: PropTypes.oneOf(['time', 'repeat', 'attention', 'camera', 'shield']).isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
};
