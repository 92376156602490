import { useIntl } from 'gatsby-plugin-intl';

export const useBreadcrumbHook = () => {
  const { formatMessage } = useIntl();
  return {
    home: {
      link: '/',
      label: formatMessage({ id: 'breadcrumbs.home' }),
    },
    posts: {
      link: '/posts',
      label: formatMessage({ id: 'breadcrumbs.blog' }),
    },
  };
};
