import React, { useMemo } from 'react';

import { sluggy } from '~/shared';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const Section = React.forwardRef(({ title = '', className = 'section', children, ...props }, ref) => {
  const sectionId = useMemo(() => sluggy(title), [title]);

  return (
    <section id={sectionId} ref={ref} className={clsx('section', className)} {...props}>
      {children}
    </section>
  );
});

Section.propTypes = {
  title: PropTypes.string.isRequired,
};

export const Footer = React.forwardRef((props, ref) => {
  return (
    <footer>
      <Section ref={ref} {...props} />
    </footer>
  );
});
