// extracted by mini-css-extract-plugin
export var partnersBusiness = "partners-module--partners-business--d235e";
export var partnersBusiness__content = "partners-module--partners-business__content--58f5e";
export var partnersBusiness__contentTitle = "partners-module--partners-business__content--title--7e092";
export var partnersBusiness__content__button = "partners-module--partners-business__content__button--22d3c";
export var partnersBusiness__content__partners = "partners-module--partners-business__content__partners--aef74";
export var partnersBusiness__content__partnersHideMobile = "partners-module--partners-business__content__partners--hide-mobile--60f51";
export var partnersBusiness__content__partnersShowMobile = "partners-module--partners-business__content__partners--show-mobile--4ef4d";
export var partnersBusiness__content__partners__item = "partners-module--partners-business__content__partners__item--fb1de";
export var partnersBusiness__content__partners__itemImage = "partners-module--partners-business__content__partners__item--image--8d64b";
export var partnersBusiness__content__partners__slider = "partners-module--partners-business__content__partners__slider--d948a";