import React from 'react';

import { ButtonLinkOnlyNotLoggedIn } from '~/features/auth';
import { PageFragment } from '~/shared/api/graphql.fragments';
import { Container, Heading, PromoCard, Section } from '~/shared/components';
import { baseSectionType, buttonType, gatsbyImageType } from '~/widgets/sections/typed';
import PropTypes from 'prop-types';

import * as styles from './promo.module.scss';

export const PromoSection = ({ blockTitle, title, subTitle, promoCards, button, ...rest }) => {
  return (
    <>
      <PageFragment />
      <Section className={styles.promo} title={blockTitle}>
        <Container className={styles.promo__content}>
          <Heading variant={2} className={styles.promo__title}>
            {title ? title : blockTitle}
          </Heading>
          {subTitle ? (
            <Heading variant={5} className={styles.promo__subtitle}>
              {subTitle}
            </Heading>
          ) : (
            <></>
          )}
          <Container className={styles.promo__list}>
            {promoCards.map((card) => (
              <PromoCard key={card.title} image={card.image} title={card.title} description={card.description} />
            ))}
          </Container>
          {button ? (
            <ButtonLinkOnlyNotLoggedIn
              id={'promo-section=button'}
              navigateTo={button?.page?.slug}
              fallback={button?.buttonLink}
              title={button?.buttonText}
              variant={button?.buttonVariant}
            />
          ) : (
            <></>
          )}
          {promoCards?.length > 3 ? <span className={styles.promo__doodleTop}></span> : <></>}
        </Container>
      </Section>
    </>
  );
};

PromoSection.propTypes = {
  baseSectionType,
  button: buttonType,
  promoCards: PropTypes.arrayOf(
    PropTypes.shape({
      image: gatsbyImageType,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
};
