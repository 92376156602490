import React, { useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import '~/shared/components/form/animation.scss';
import { SuccessContainer, useContactUsForm } from '~/features/contact-us';
import { BaseForm, Button, Container, Heading, IF, Section, Spinner } from '~/shared/components';
import { ButtonGroup } from '~/shared/components/button';
import { FormElement, FormInput, ReCaptcha, Textarea } from '~/shared/components/form';
import { baseSectionType, buttonType, gatsbyImageType } from '~/widgets/sections/typed';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

import * as styles from './form.module.scss';

export const ContactUsFormSection = React.memo(
  ({ blockTitle, button, title, subTitle, formName, successTitle, successDescription, successButton }) => {
    const { formatMessage } = useIntl();
    const [formIsSubmit, setFormIsSubmit] = useState(false);
    const { formSchema, isLoading, isSuccess, mutate, defaultValues } = useContactUsForm({ formName });

    const Form = () => {
      return (
        <>
          <BaseForm onSubmit={mutate} defaultValues={defaultValues} resolver={formSchema}>
            {({ register, control, formState, getValues }) => (
              <>
                <FormInput
                  name="form"
                  type={'hidden'}
                  register={register}
                  control={control}
                  formState={formState}
                  getValues={getValues}
                />
                <FormElement
                  label={formatMessage({ id: 'validate.contact-us.form.field.name' })}
                  labelBefore={formatMessage({ id: 'form.fields.required' })}
                  variant={'underline'}
                  name="name"
                  className={styles.form__inputWrapper}
                >
                  <FormInput
                    name="name"
                    register={register}
                    control={control}
                    formState={formState}
                    getValues={getValues}
                    className={styles.form__input}
                    disabled={false}
                  />
                </FormElement>
                <FormElement
                  label={formatMessage({ id: 'validate.contact-us.form.field.email' })}
                  labelBefore={formatMessage({ id: 'form.fields.required' })}
                  variant={'underline'}
                  name="email"
                  className={styles.form__inputWrapper}
                >
                  <FormInput
                    name="email"
                    register={register}
                    control={control}
                    formState={formState}
                    getValues={getValues}
                    className={styles.form__input}
                    disabled={false}
                  />
                </FormElement>
                <FormElement
                  label={formatMessage({ id: 'validate.contact-us.form.field.description' })}
                  labelBefore={formatMessage({ id: 'form.fields.optional' })}
                  variant={'underline'}
                  name="description"
                  className={styles.form__inputWrapper}
                >
                  <Textarea
                    name="description"
                    register={register}
                    control={control}
                    formState={formState}
                    getValues={getValues}
                    className={styles.form__textarea}
                    placeholder="Bitte hier die Nachricht eingeben..."
                    disabled={false}
                  />
                </FormElement>
                <FormElement name="">
                  <FormInput
                    name="token"
                    type={'hidden'}
                    register={register}
                    control={control}
                    formState={formState}
                    getValues={getValues}
                    disabled={false}
                  />
                </FormElement>
                <ButtonGroup className={styles.form__buttonWrapper}>
                  <Container className={styles.form__buttonWrapperRecaptcha}>
                    <ReCaptcha name="token" action={'contact'} />
                    <Button
                      type={'submit'}
                      variant={button?.buttonVariant}
                      size={'small'}
                      title={button?.buttonText}
                      disabled={isLoading}
                      isLoading={isLoading}
                    />
                  </Container>
                </ButtonGroup>
              </>
            )}
          </BaseForm>
        </>
      );
    };
    useEffect(() => {
      setFormIsSubmit(isSuccess);
    }, [isSuccess]);

    return (
      <Section title={blockTitle} className={styles.form}>
        <Container className={clsx(styles.form__content)}>
          <Container className={styles.form__content_left}>
            <Heading variant={1} className={styles.form__title}>
              {title ? title : blockTitle}
            </Heading>
            <p>{subTitle}</p>
          </Container>
          <Container className={styles.form__content_right}>
            <SwitchTransition>
              <CSSTransition key={[formIsSubmit, isLoading]} timeout={500} classNames="fade">
                <>
                  <IF condition={isLoading}>
                    <Spinner />
                  </IF>
                  <IF condition={!formIsSubmit && !isLoading}>
                    <Form />
                  </IF>
                  <IF condition={formIsSubmit}>
                    <SuccessContainer
                      successTitle={successTitle}
                      successDescription={successDescription}
                      successButton={successButton}
                      onClick={() => setFormIsSubmit(false)}
                    />
                  </IF>
                </>
              </CSSTransition>
            </SwitchTransition>
          </Container>
        </Container>
      </Section>
    );
  }
);

ContactUsFormSection.propTypes = {
  button: buttonType,
  mainImage: gatsbyImageType,
  formName: PropTypes.string.isRequired,
  baseSectionType,
};
