import React, { useEffect, useMemo } from 'react';

import { handleSection } from '~/widgets/sections';
import { navigate } from 'gatsby';
import isEmpty from 'lodash.isempty';

export const usePageCanonical = (pageContext, seoMeta) => {
  const { canonicalPath, pageNumber } = pageContext;
  return useMemo(() => {
    if (pageNumber > 0) return canonicalPath;
    return seoMeta.canonicalURL;
  }, [canonicalPath, pageNumber, seoMeta]);
};
