import React from 'react';

import { AdvantageImage, AdvantageParagraph, Container } from '~/shared/components';

import * as styles from './advantage.module.scss';

export default ({ type, title, description }) => {
  return (
    <Container className={styles.advantage}>
      <AdvantageImage className={styles.advantage__icon} iconType={type} />
      <AdvantageParagraph title={title} description={description} className={styles.advantage__description} />
    </Container>
  );
};
