// extracted by mini-css-extract-plugin
export var blockquote = "markdown-block-module--blockquote--baf14";
export var bulletList = "markdown-block-module--bullet-list--3ded8";
export var code = "markdown-block-module--code--66bf2";
export var imageBlock = "markdown-block-module--image-block--1b7f4";
export var link = "markdown-block-module--link--dd98d";
export var markdown__heading = "markdown-block-module--markdown__heading--f32a7";
export var markdown__headingH1 = "markdown-block-module--markdown__heading--h1--bf746";
export var markdown__headingH2 = "markdown-block-module--markdown__heading--h2--6c210";
export var markdown__headingH3 = "markdown-block-module--markdown__heading--h3--6ef7e";
export var markdown__headingH4 = "markdown-block-module--markdown__heading--h4--916df";
export var numbersList = "markdown-block-module--numbers-list--f5517";
export var paragraph = "markdown-block-module--paragraph--6bc90";
export var textUnderline = "markdown-block-module--text-underline--1aee6";