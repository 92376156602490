import React, { useMemo } from 'react';

import { ButtonLinkOnlyNotLoggedIn } from '~/features/auth';
import { sluggy } from '~/shared';
import { Container, Section, SectionTitle } from '~/shared/components';
import { buttonType } from '~/widgets/sections/typed';
import clsx from 'clsx';
import { Link } from 'gatsby-plugin-intl';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';

import * as styles from './themes.module.scss';

/**
 *
 * @returns {React.JSX.Element}
 * @constructor
 */
export const ThemesSection = React.memo(({ blockTitle: title, subTitle, themen, button }) => {
  const renderedThemes = useMemo(() => {
    const themes = themen.length > 11 ? themen.slice(0, 12) : themen;
    return themes.map(({ theme, explanation }, index) => (
      <Link
        to={`/${button.page?.slug}/#${sluggy(theme)}`}
        className={clsx(styles.themes__item, styles[camelCase(`themes-item--${index}`)])}
        key={theme}
      >
        {theme}
      </Link>
    ));
  }, [themen, button]);
  return (
    <Section title={title}>
      <Container className={styles.themes__content}>
        <SectionTitle variant={2} className={styles.themes__title}>
          {title}
        </SectionTitle>
        <p>{subTitle}</p>
        <Container className={styles.themes__header}>
          <span className={styles.themes__decor1} />
          <ButtonLinkOnlyNotLoggedIn
            id={'themes-section-button'}
            classNameExtended={styles.themes__button}
            title={button.buttonText}
            navigateTo={button.page?.slug}
            variant={'transparent'}
            size={'small'}
          />
        </Container>
        <Container className={clsx(styles.themes__cloud, styles.themes)}>{renderedThemes}</Container>
      </Container>
    </Section>
  );
});

ThemesSection.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  button: buttonType.isRequired,
  themen: PropTypes.arrayOf(
    PropTypes.shape({
      theme: PropTypes.string.isRequired,
      explanation: PropTypes.string.isRequired,
    })
  ),
};
