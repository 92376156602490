import React from 'react';

import { Container } from '~/shared/components';
import clsx from 'clsx';

import * as styles from './container.module.scss';

const ButtonGroup = ({ children, className }) => (
  <Container className={clsx(styles.button__group, className && className)}>{children}</Container>
);

export { ButtonGroup };
