import { api } from '~/shared/api';

export const sendContactUsForm = async ({
  name,
  email,
  message,
  companyName,
  qntEmployees,
  telephoneNumber,
  page,
  form,
}) =>
  await api.post(`/forms/form_${form}_request`, {
    page: page,
    data: { [form]: { name: name, email: email, message: message, companyName, qntEmployees, telephoneNumber } },
  });
